import React from 'react'

const Algebra = ({ setPreguntas, preguntas, index, typeQuestions }) => {
  return (
    <>
    <div className='card bg-dark p-3 mt-4' key={index}>
        <label className='text-white'>Pregunta {index + 1} tipo {typeQuestions[index]}</label>
        <label className='text-white'>Valor 1</label>
        <input  className='form-control' 
        value={preguntas[index]?.valorUno}
          onChange={(e) => {
            let previewElement = preguntas.find(el => el.indice === index);
            if (previewElement) {
              const indexElement = preguntas.findIndex(el => el.indice === index);
              preguntas[indexElement].valorUno = e.target.value;
              setPreguntas([...preguntas]);
            }else{
              setPreguntas((preguntas) => [...preguntas, { valorUno: e.target.value, indice: index, tipo: typeQuestions[index] }])
            }
          }}/>
        <label className='text-white'>Tipo de Operación</label>
        <select className='form-control' 
        value={preguntas[index]?.tipoOperacion}
          onChange={(e) => {
            let previewElement = preguntas.find(el => el.indice === index);
            if (previewElement) {
              const indexElement = preguntas.findIndex(el => el.indice === index);
              preguntas[indexElement].tipoOperacion = e.target.value;
              setPreguntas([...preguntas]);
            }else{
              setPreguntas((preguntas) => [...preguntas, { tipoOperacion: e.target.value, indice: index, tipo: typeQuestions[index] }])
            }
          }}
        >
            <option value="" selected>--seleccionar --</option>
            <option value='suma'>Suma</option>
            <option value='resta'>Resta</option>
            <option value='multiplicacion'>Multiplicación</option>
            <option value='division'>División</option>
        </select>
      <label className='text-white'>Valor 2</label>
        <input  className='form-control' 
        value={preguntas[index]?.valorDos}
          onChange={(e) => {
            let previewElement = preguntas.find(el => el.indice === index);
            if (previewElement) {
              const indexElement = preguntas.findIndex(el => el.indice === index);
              preguntas[indexElement].valorDos = e.target.value;
              setPreguntas([...preguntas]);
            }else{
              setPreguntas((preguntas) => [...preguntas, { valorDos: e.target.value, indice: index, tipo: typeQuestions[index] }])
            }
          }}/>
      </div>
    </>
  )
}

export default Algebra