import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import { getAsistencias, asistenciaSelector, generarData, limpiarInfo } from "../../slices/asistencia/asistenciaSlice";
import Swal from "sweetalert2";
import { url_api } from "../../utils/config";
import { Search } from "../search/Search";

export const AllAsistencias = () => {

  const { menu } = useMenuToggle();
  const dispatch = useDispatch();
  const { asistencias, success, errores, documento } = useSelector(asistenciaSelector);
  const [dniSearch, setDniSearch] = useState("");
  const [dataAsistencias, setDataAsistencias] = useState([]);

  const handleClick = async() => {
    if(dataAsistencias.length === 0){
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Debe existir información para poder generar el documento",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    }
    dispatch(generarData(dataAsistencias))
  }

  useEffect(() => {
    if(success){
      Swal.fire({
        title: '<strong>Documento Generado con Éxito</strong>',
        icon: 'success',
        html:
          'Descarga haciendoo click en el enlace' +
          `<br/><a href="${url_api + "/documents/" + documento}">Click Aqui</a>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonAriaLabel: 'Thumbs up, great!',
      })
      dispatch(limpiarInfo());
    }
  }, [success])

  useEffect(() => {
    if(errores){
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: errores,
        showConfirmButton: false,
        timer: 4000,
      });
      dispatch(limpiarInfo());
    }
  }, [errores])
 
  useEffect(() => {
    dispatch(getAsistencias())
  }, [])

  useEffect(() => {
    setDataAsistencias(asistencias);
  }, [asistencias])

  useEffect(() => {
    if(dniSearch === ""){
      setDataAsistencias(asistencias);
    }else{
      const asis = asistencias.filter(as => as.user.dni == dniSearch || as.user.dni.startsWith(dniSearch) || as.user.name == dniSearch || as.user.name.startsWith(dniSearch)); 
      setDataAsistencias(asis);
    }
  }, [dniSearch])

  return (
    <>
      <Header />
      <Sidebar />
      <main
            id="main"
            className="main"
            style={{ marginLeft: menu ? "" : "0px" }}
        >
            <section className="section">
                <div className="pagetitle">
                    <h1>Asistencias</h1>
                </div>
                <div className="col-12 d-flex flex-column mt-2 flex-md-row justify-content-between align-items-center">
                  <div className="col-12 col-md-4">
                    <label>Buscar por DNI o Nombre</label>
                    <input className="form-control" placeholder="Ingresa el DNI o Nombre" value={dniSearch} onChange={(e) => setDniSearch(e.target.value)} />
                  </div>
                  <div className="mt-2">
                    <button className="btn btn-warning"
                      onClick={handleClick}
                    >Export</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Usuario</th>
                                <th scope="col">DNI/Cédula</th>
                                <th scope="col">Ubicación</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Motivo</th>
                              </tr>
                            </thead>
                            <tbody>
                            {dataAsistencias && dataAsistencias.map((item, index) => (
                                <>
                                <tr key={index}>
                                  <td>{item?.user?.name + " " + item?.user?.lastName}</td>
                                  <td>{item?.user?.dni}</td>
                                  <td>Ubicación 
                                    <a target="_blank"
                                      rel="noreferrer" 
                                      href={`https://www.google.com/maps/place/${item?.latitud}, ${item?.longitud}`}>
                                      <i class='bx bxs-location-plus' ></i>
                                    </a>
                                  </td>
                                  <td>{item?.fecha.split(" ")[0]}</td>
                                  <td>{item?.fecha.split(" ")[1]}</td>
                                  <td>{item?.motivo}</td>
                                </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
        </main>
      <Footer />
    </>
  )
}
