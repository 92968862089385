import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

const URL_API = url_api+"/api" || "http://localhost:3001/api";

const initialState = {
    imagenBiometrica: "",
    aprobado: false,
    error: ""
}

export const reconocimientoSlice = createSlice({
    name: "reconocimiento",
    initialState,
    reducers: {
        getImage: (state, action) => {
            state.imagenBiometrica = action.payload.dataImagenBiometrica
        },
        setAprobado: (state, action) => {
            state.aprobado = action.payload.dataAprobado
        },
        getError: (state, action) => {
            state.error = action.payload.dataError
        }
    },
})

const { getImage,
        setAprobado,
        getError
        } = reconocimientoSlice.actions;

export const getBiometricImage = (id) => async (dispatch) => {
    const response = await axios.get(URL_API + `/biometric/user/${id}`);
    dispatch(getImage({dataImagenBiometrica: response?.data?.image}));
}

// funcion de prueba
export const sendPhotoSelfie = formData => async (dispatch) => {
    try {
        const { data } = await axios.post("https://ia.owlycrm.com/upload", formData);
    } catch (error) {

    }
}

export const reconocimientoSelector = (state) => state.reconocimiento;