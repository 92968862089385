import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { control, marker, tileLayer } from 'leaflet';
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvent } from 'react-leaflet';
import axios from 'axios';
import { AddressAutofill, SearchBox, AddressMinimap, useConfirmAddress, config } from '@mapbox/search-js-react';

function MapComponent({ mapa, direccion }) {
  const map = useMap();

  useEffect(() => {
    map.setView([-0.2298500, -78.5249500], 5);
    tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    setTimeout(() => {
      map.invalidateSize();
    }, [1000]);
    
  }, [mapa])

  useEffect(() => {
    if(direccion.length > 0) {
      map.flyTo(direccion);
    }
  }, [direccion])
};

function Mapas({ mapa,    
                direccion,
                setDireccion,
                coordinatesDireccion,
                setCoordinatesDireccion,
                position,
                setPosition, }) {

  const [direccionValue, setDireccionValue] = useState("");

  function DraggableMarker() {
    const [draggable, setDraggable] = useState(true)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setCoordinatesDireccion([marker.getLatLng().lat, marker.getLatLng().lng])
            setPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )

    useEffect(() => {
      if(coordinatesDireccion.length > 0){
        setPosition(coordinatesDireccion)
      }
    }, [coordinatesDireccion])

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span>
            arrastrame y posicionate sobre una dirección para tomar las coordenadas
          </span>
        </Popup>
      </Marker>
    )
  }

  async function buscarDireccion(direccion){
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${direccion}.json?worldview=cn&access_token=pk.eyJ1IjoicmFmYW5nMjIwNiIsImEiOiJjbGl6ZGdsaG8wbXlsM2t1dTRyeDRkYnlqIn0.4Zs6JMonQEbPTf88xixZ7g`;
    try {
      const { data } = await axios.get(url);
      setCoordinatesDireccion([data.features[0].center[1], data.features[0].center[0]]);
    } catch (error) {
      alert('hubo un error intentalo de nuevo')
    }
  }
  
  return (
    <MapContainer center={[-0.2298500, -78.5249500]} zoom={5} scrollWheelZoom={false} style={{ width: "100%", height: "15rem" }}>
      <div className="leaflet-control-zoom leaflet-bar leaflet-control position-absolute top-0 end-0 z-1">
        <div className='d-flex gap-2'>
          <SearchBox
              accessToken={"pk.eyJ1IjoicmFmYW5nMjIwNiIsImEiOiJjbGl6ZGdsaG8wbXlsM2t1dTRyeDRkYnlqIn0.4Zs6JMonQEbPTf88xixZ7g"}
              placeholder='ingresa alguna dirección'
              value={direccionValue}
              onChange={(e) => setDireccionValue(e)}
              onRetrieve={(e) => setCoordinatesDireccion([e.features[0].properties.coordinates.latitude, e.features[0].properties.coordinates.longitude])}
            />
            <button className='btn btn-primary' onClick={(e) => {
              e.preventDefault()
              buscarDireccion(direccionValue)}}><i className='bx bx-search-alt-2'></i></button>
        </div>
      </div>
      <MapComponent mapa={mapa} direccion={coordinatesDireccion}/>
      <DraggableMarker/>
    </MapContainer>
  )
}

export default Mapas;