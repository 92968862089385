import React, { useEffect, useState, useRef } from "react";

export const Certificate = ({ value, onChange, indice, setCertificadosFijosInfo, required }) => {

  const [certificate, setCertificate] = useState("")
  const valorInput = useRef(null);
  const inputFecha = useRef(null);
  const inputDocumento = useRef(null);
  
  useEffect(() => {
    setCertificate(value)
    valorInput.current.value = value;
    valorInput.current.required = required;
    inputFecha.current.required = required;
    inputDocumento.current.required = required;
    setCertificate(value)
  }, [value])

  useEffect(() => {
    if(certificate){
      setCertificadosFijosInfo( (state) => [...state, { nameArchivo: value, obligatorio: required }])
    }else{
      setCertificadosFijosInfo([]);
    }
  }, [certificate])

  return (
    <div className="d-flex col-12">
      <div className="col-2 me-1 mb-2">
        <input
          type="text" 
          className="form-control"
          name="nameArchivo"
          onChange={onChange}
          ref={valorInput}
        />
      </div>
      <div className="col-1 me-1 mb-2 d-flex justify-content-center align-items-center">
        <span>{required ? "*" : ""}</span>
      </div>
      <div className="col-5 me-1 mb-2">
        <input
          type="file" 
          accept=".jpg,.pgn,.pdf,.bmp"
          className="form-control"
          name="name"
          onChange={onChange}
          ref={inputDocumento}
        />
      </div>
      <div className="col-4">
        <input
          type="date"
          className="form-control"
          name="expiration"
          //value={certificate ? certificate.expiration : ""}
          onChange={onChange}
          ref={inputFecha}
        />
      </div>
    </div>
  );
};
