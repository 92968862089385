import React, { useState, useEffect } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useInfo from "../../hooks/useInfo";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";
import { getAllResultados } from "../../slices/evaluacion/evaluacion";
import { useDispatch, useSelector } from "react-redux";

export const Resultados = () => {

  const allResultados = useSelector((state) => state.evaluacion.allResultados);

  const [dniSearch, setDniSearch] = useState("");
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllResultados());
  }, [])

  useEffect(() => {
    setData(allResultados)
  }, [])

  useEffect(() => {
    if(dniSearch === ""){
      setData(allResultados);
    }else{
      const asis = allResultados.filter(as => as.user.dni == dniSearch || as.user.dni.startsWith(dniSearch) || as.user.name == dniSearch || as.user.name.startsWith(dniSearch)); 
      setData(asis);
    }
  }, [dniSearch])

  return (
    <>
        <section className="section mt-5">
            <div className="pagetitle">
                <h1>Resultados</h1>
            </div>
            <div className="col-12 col-md-4">
              <label>Buscar por DNI o Nombre</label>
              <input className="form-control" placeholder="Ingresa el DNI o Nombre" value={dniSearch} onChange={(e) => setDniSearch(e.target.value)} />
            </div>
            <div className="row">
        <div className="col-lg-12">
            <div className="card">
            <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Nombre evaluación</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">DNI/Cédula</th>
                        <th scope="col">Resultado</th>
                        <th scope="col">Intentos</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>

                      {data && data.map((item, index) => (
                        <>
                        <tr key={index}>
                            <td>{item?.evaluation?.nombre}</td>
                            <td>{item?.user?.name + " " + item?.user?.lastName}</td>
                            <td>{item?.user?.dni}</td>
                            <td>{item?.result?.toFixed(2)}</td>
                            <td>{item?.intento}</td>
                            <td>{item?.fechaLimit}</td>
                            <td>{item?.result < item?.evaluation?.calMin ? <p className='text-danger'>Desaprobada</p> : <p className='text-success'>Aprobada</p>}</td>
                        </tr>
                        </>
                      ))}
                    
                    </tbody>
                </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    </>
  )
}
