import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { url_api } from "../utils/config";

const InfoContext = createContext();

export const InfoProvider = ({ children }) => {

  const [logo, setLogo] = useState({});
  const [titulo, setTitulo] = useState({});
  const [informacion, setInformacion] = useState({});
  const [error, setError] = useState({});
  const [certificados, setCertificados] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [roles, setRoles] = useState([]);

  const obtenerLogo = async()=> {
    const URL_API = `${url_api}/api/info/obtener-logo` || "http://localhost:3001/api/info/obtener-logo";
    try {
      const { data } = await axios.get(URL_API);
      setLogo(data);
    } catch (error) {
      setError({ error: "hubo un error"});
    }
  }

  const obtenerTitulo = async()=> {
    const URL_API = `${url_api}/api/info/obtener-titulo` || "http://localhost:3001/api/info/obtener-titulo";
    try {
      const { data } = await axios.get(URL_API);
      setTitulo(data)
    } catch (error) {
      setError({ error: "hubo un error"});
    }
  }

  const obtenerInformacion = async()=> {
    const URL_API = `${url_api}/api/info/obtener-informacion` || "http://localhost:3001/api/info/obtener-informacion";
    try {
      const { data } = await axios.get(URL_API);
      setInformacion(data)
    } catch (error) {
      setError({ error: "hubo un error"});
    }
  }

  const obtenerCertificados = async() => {
    const URL_API = `${url_api}/api/certificate/get-certificate-fixed` || "http://localhost:3001/api/certificate/get-certificate-fixed";
    try {
      const { data } = await axios.get(URL_API);
      setCertificados(data);
    } catch (error) {
      setError({ error: "hubo un error"});
    }
  }

  const obtenerEvaluaciones = async() => {
    const URL_API = `${url_api}/api/evaluation` || "http://localhost:3001/api/evaluation";
    try {
      const { data } = await axios.get(URL_API);
      setEvaluaciones(data);
    } catch (error) {
      setError({ error: "hubo un error"});
    }
  }

  const obtenerRoles = async() => { 
    const URL_API = `${url_api}/api/admin/roles` || "http://localhost:3001/api/admin/roles";
    try {
      const { data } = await axios.get(URL_API);
      setRoles(data);
    } catch (error) {
      setError({ error: "hubo un error"});
    }
  }

  useEffect(() => {
    obtenerInformacion();
  }, [])

  useEffect(() => {
    obtenerTitulo();
  }, [])


   useEffect(() => {
    obtenerLogo();
  }, [])

  useEffect(() => {
    obtenerCertificados();
  }, [])

  useEffect(() => {
    obtenerEvaluaciones();
  }, [])

  useEffect(() => {
    obtenerRoles();
  }, [])

  return (
    <InfoContext.Provider value={{ logo, setLogo, obtenerLogo, titulo, setTitulo, obtenerTitulo, 
      informacion, setInformacion, obtenerInformacion, certificados, setCertificados, obtenerCertificados,
      evaluaciones, setEvaluaciones, obtenerEvaluaciones, roles
    }}>
      {children}
    </InfoContext.Provider>
  );
};

export default InfoContext;
