import React from "react";

export const NotFound = () => {
  return (
    <div className="container-fluid container-spacing">
      <h1>Error 404</h1>
      <h2>Página no encontrada</h2>
      <p>Lo sentimos no podemos encontrar la ruta que solicitaste, por favor intenta verificar bien la dirección</p>
    </div>
  )
}
