import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useMenuToggle from "../hooks/useMenuToggle";
import useInfo from "../hooks/useInfo";
import { clearDataState, userSelector } from "../slices/user/userSlice";
import { url_api } from "../utils/config";
import axios from "axios";

export const Header = () => {
  const { menu, setMenu } = useMenuToggle();
  const { user } = useSelector(userSelector);
  const { logo, titulo } = useInfo();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const btnMenu = (value) => {
    setMenu(value);
   
  };
 
  const logout = (e) => {
    e.preventDefault();
    dispatch(clearDataState());
    navigate("/");
  }

 
   /*if(!isLogin){
     navigate("/");
   }*/
  
  return (
    <header
      id="header"
      className="header fixed-top d-flex justify-content-between align-items-center"
    >
      <div className="d-flex align-items-center justify-content-between">
        <Link
          to="/dashboard/projects"
          className="logo d-flex align-items-center col"
        >
          <img src={logo ? `${url_api}/${logo.logo}` : ""} alt="" />
          {/* <span className="d-none d-lg-block">MSLAPS</span> */}
        </Link>
        {menu ? (
          <i
            className="bi bi-list toggle-sidebar-btn col"
            onClick={() => btnMenu(false)}
          ></i>
        ) : (
          <i
            className="bi bi-list toggle-sidebar-btn col"
            onClick={() => btnMenu(true)}
          ></i>
        )}
      </div>
      <div className="logo text-center w-100">
        <span className="fs-4">{titulo ? titulo.titulo : ""}</span>
      </div>
      <nav className="header-nav d-flex gap-3">
        <p className="mt-3"><i className="bi bi-bell"></i></p>
        <p className="mt-3"><i className="bi bi-telegram"></i></p>
        
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown pe-3">

            <Link
              className="nav-link nav-profile d-flex align-items-center pe-0"
              to="#"
              data-bs-toggle="dropdown"
            >
              <div className="" style={{ width: 30, height: 30 }}>
                <img
                  src={`${url_api}/${user?.image}`}
                  alt="Profile"
                  className="rounded-circle img-fluid h-100 w-100"
                />
              </div>
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {`${user?.name?.charAt(0)}. ${user?.lastName}`}
              </span>
            </Link>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6 className="text-capitalize">{`${user?.name} ${user?.lastName}`}</h6>
                <span className="text-capitalize">{user?.role?.value}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="/dashboard/profile"
                >
                  <i className="bi bi-person"></i>
                  <span>Mi Perfil</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="/dashboard/generateqr"
                >
                  <i className="bi bi-qr-code"></i>
                  <span>Generar QR</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={logout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Cerrar Sesion</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};
