import { url_api } from "../utils/config";
import { io } from "socket.io-client";

const socket = io(url_api,
            {
                reconnectionDelayMax: 10000,
                auth: {
                    token: "123"
                },
                query: {
                    "my-key": "my-value"
                }
            }
        );

export default socket;