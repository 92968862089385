import React from 'react'

const RespuestaBreve = ({ setPreguntas, preguntas, index, typeQuestions }) => {
  return (
    <>
    <div className='card bg-dark p-3 mt-4' key={index}>
      <label className='text-white'>Pregunta {index + 1} tipo {typeQuestions[index]}</label>
        <input  className='form-control' 
          value={preguntas[index]?.pregunta}
          onChange={(e) => {
            let previewElement = preguntas.find(el => el.indice === index);
            if (previewElement) {
              const indexElement = preguntas.findIndex(el => el.indice === index);
              preguntas[indexElement].pregunta = e.target.value;
              setPreguntas([...preguntas]);
            }else{
              setPreguntas((preguntas) => [...preguntas, { pregunta: e.target.value, indice: index, tipo: typeQuestions[index] }])
            }
          }}/>
      <label className='text-white'>Respuesta de la Pregunta {index + 1}</label>
        <input className='form-control' 
          value={preguntas[index]?.respuesta}
          onChange={(e) => {
            let previewElement = preguntas.find(el => el.indice === index);
            if (previewElement) {
              const indexElement = preguntas.findIndex(el => el.indice === index);
              preguntas[indexElement].respuesta = e.target.value;
              setPreguntas([...preguntas]);
            }else{
              setPreguntas((preguntas) => [...preguntas, { respuesta: e.target.value, indice: index, tipo: typeQuestions[index] }])
            }
          }}/>
      </div>
    </>
  )
}

export default RespuestaBreve;