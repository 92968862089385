// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --border-color-input: #7f7f7f96;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.places-container {
  position: absolute;
  top: 55px;
  left: 34%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 250px;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color-input);
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0px #0000001F;
}

#combobox-list {
  background-color: #ffffff;
  margin: 5px 0;
  padding: 0;
  border: 1px solid var(--border-color-input);
  border-radius: 10px;
}

.combobox-option {
  margin: 0 auto;
  padding: 0 5px;
  list-style-type: none;
  cursor: default;
  border-radius: 5px;
}

.combobox-option:hover {
  background-color: #383A3D28;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/map/globals.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;;EAEE,UAAU;EACV,SAAS;EACT;wEACsE;AACxE;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,2CAA2C;EAC3C,mBAAmB;EACnB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,UAAU;EACV,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":[":root {\n  --border-color-input: #7f7f7f96;\n}\n\nhtml,\nbody {\n  padding: 0;\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,\n    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n* {\n  box-sizing: border-box;\n}\n\n.places-container {\n  position: absolute;\n  top: 55px;\n  left: 34%;\n  transform: translateX(-50%);\n  z-index: 10;\n  width: 300px;\n}\n\n.map-container {\n  width: 100%;\n  height: 250px;\n}\n\n.combobox-input {\n  width: 100%;\n  padding: 0.5rem;\n  border: 1px solid var(--border-color-input);\n  border-radius: 10px;\n  box-shadow: 0px 5px 20px 0px #0000001F;\n}\n\n#combobox-list {\n  background-color: #ffffff;\n  margin: 5px 0;\n  padding: 0;\n  border: 1px solid var(--border-color-input);\n  border-radius: 10px;\n}\n\n.combobox-option {\n  margin: 0 auto;\n  padding: 0 5px;\n  list-style-type: none;\n  cursor: default;\n  border-radius: 5px;\n}\n\n.combobox-option:hover {\n  background-color: #383A3D28;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
