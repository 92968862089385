import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

const URL_API = url_api+"/api" || "http://localhost:3001/api";

const initialState = {
    evaluaciones: [],
    evaluacion: {},
    evaluacionStatus: {},
    preguntas: [],
    resultado: {},
    allResultados: []
}

export const evaluacionSlice = createSlice({
    name: "evaluacion",
    initialState,
    reducers: {
        allEvaluaciones: (state, action) => {
            state.evaluaciones = action.payload.dataEvaluaciones
        },
        setEvaluacion: (state, action) => {
            state.evaluacion = action.payload.dataEvaluacion
        },
        setStatus: (state, action) => {
            state.evaluacionStatus = action.payload.dataEvaluacionStatus
        },
        setPreguntas: (state, action) => {
            state.preguntas = action.payload.dataPreguntas
        },
        setResultado: (state, action) => {
            state.resultado = action.payload.dataResultado
        },
        setAllResultados: (state, action) => {
            state.allResultados = action.payload.dataAllResultados
        },
    },
})

const { allEvaluaciones, 
        setEvaluacion, 
        setStatus, 
        setPreguntas, 
        setResultado, 
        setAllResultados } = evaluacionSlice.actions;

export const getEvaluaciones = (id) => async (dispatch) => {
    const response = await axios.get(URL_API + `/evaluation/user/${id}`);
    dispatch(allEvaluaciones({dataEvaluaciones: response.data}))
}

export const limpiarEvaluacion = () => async(dispatch) => {
    dispatch(setStatus({ dataEvaluacionStatus: {} }));
    dispatch(setEvaluacion({ dataEvaluacion: {} }));
    dispatch(setResultado({ dataResultado: {} }));
}

export const addDataStatus = (data) => async(dispatch) => {
    dispatch(setStatus({ dataEvaluacionStatus: { ...data } }));
}

export const iniciarEvaluacion = (evaluacion, user ) => async(dispatch) => {
    dispatch(limpiarEvaluacion());
    try {
        const { data } = await axios.post(URL_API + `/evaluation/init-evaluation`, { userId: user.id, evaluationId: evaluacion.id })
        dispatch(setStatus({ dataEvaluacionStatus: data?.evaluationCreate }));
        dispatch(setEvaluacion({ dataEvaluacion: evaluacion }));
        Swal.fire('Iniciando la Evaluación', '', 'success');
    } catch (error) {
        Swal.fire("ADVERTENCIA!", error.response.data.message, "warning");
        dispatch(setStatus({ dataEvaluacionStatus: {} }));
        dispatch(setEvaluacion({ dataEvaluacion: {} }));
        return;
    }
}

export const reiniciarEvaluacion = (evaluacion, user ) => async(dispatch) => {
    try {
        await axios.post(URL_API + `/evaluation/reiniciar-evaluacion`, { userId: user.id, evaluationId: evaluacion.id })
        dispatch(limpiarEvaluacion());
        Swal.fire('Reiniciando la Evaluación', '', 'success');
    } catch (error) {
        Swal.fire("ADVERTENCIA!", error.response.data.message, "warning");
        dispatch(setStatus({ dataEvaluacionStatus: {} }));
        dispatch(setEvaluacion({ dataEvaluacion: {} }));
        return;
    }
}

export const getEvaluation = (id, userId) => async(dispatch, getState) => {
    const { data } = await axios.post(URL_API + `/evaluation/init-evaluation/${id}`, { userId });
    dispatch(setStatus({ dataEvaluacionStatus: data }));
    const state = getState();
    const evaluaciones = evaluacionesSelector(state).evaluaciones;
    let evaluacion = evaluaciones.filter(item => item.id == id)[0];
    dispatch(setEvaluacion({ dataEvaluacion: evaluacion }));
}

export const getPreguntas = (id) => async(dispatch) => {
    const { data } = await axios.get(URL_API + `/evaluation/preguntas/${id}`);
    dispatch(setPreguntas({ dataPreguntas: data }))
}

export const evaluarPregunta = (data) => async(dispatch) => {
    await axios.post(URL_API + `/evaluation/evaluar-pregunta`, data)
}

export const resultadoEvaluacion = (evaluationId, userId) => async(dispatch) => {
    const { data } = await axios.post(URL_API + `/evaluation/resultado/${evaluationId}`, { userId });
    dispatch(setResultado({ dataResultado: data }))
}

export const getAllResultados = (evaluationId) => async(dispatch) => {
    const { data } = await axios.get(URL_API + `/evaluation/result-evaluations`);
    dispatch(setAllResultados({ dataAllResultados: data }))
}

export const getStatusEvaluation = (id, userId) => async(dispatch, getState) => {
    const { data } = await axios.post(URL_API + `/evaluation/estado-evaluacion/${id}`, { userId });
    dispatch(setStatus({ dataEvaluacionStatus: data }));
    const state = getState();
    const evaluaciones = evaluacionesSelector(state).evaluaciones;
    let evaluacion = evaluaciones.filter(item => item.id == id)[0];
    dispatch(setEvaluacion({ dataEvaluacion: evaluacion }));
}

export const crearEvaluacion = (formData) => async(dispatch) => {
    try {
      const { data } = await axios.post(URL_API + "/evaluation", formData);
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      let mensaje = error.response.data.message;
      Swal.fire("ADVERTENCIA!", mensaje ?? "Hubo un error al Agregar la Evaluación", "warning");
    }
}

export const editarEvaluacion = (formData) => async(dispatch) => {
    try {
      const { data } = await axios.put(URL_API + "/evaluation", formData);
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      let mensaje = error.response.data.message;
      Swal.fire("ADVERTENCIA!", mensaje ?? "Hubo un error al Agregar la Evaluación", "warning");
    }
}

export const eliminarEvaluacion = (id) => async(dispatch) => {
    try {
      const { data } = await axios.delete(URL_API + `/evaluation/${id}`);
      Swal.fire("Evaluación eliminada!", data.message, "success");
    } catch (error) {
      let mensaje = error.response.data.message;
      Swal.fire("ADVERTENCIA!", mensaje ?? "Hubo un error al eliminar la Evaluación", "warning");
    }
}

export const evaluacionesSelector = (state) => state.evaluacion;