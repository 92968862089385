import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import { userSelector } from "../../slices/user/userSlice";
import { Reconocimiento } from "../reconocimientoFacial/Reconocimiento";
import { getAsistenciasUser, asistenciaSelector } from "../../slices/asistencia/asistenciaSlice";

export const UserAsistencias = () => {

  const dispatch = useDispatch();
  const videoRef = useRef();
  const modalRef = useRef(null);
  const { asistencias } = useSelector(asistenciaSelector);
  const { user } = useSelector(userSelector);
  const { menu } = useMenuToggle();
  const [openModal, setOpenModal] = useState(false);
  const [iniciar, setIniciar] = useState(false);

  const handleModalClosed = () => {
    setOpenModal(false)
    if(videoRef.current){
      stopVideo();
    }
  }

  useEffect(() => {
    dispatch(getAsistenciasUser(user.id))
  }, [])

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener('hidden.bs.modal', handleModalClosed);
    }

    // Limpia el evento cuando el componente se desmonta
    return () => {
      if (modalRef.current) {
        modalRef.current.removeEventListener('hidden.bs.modal', handleModalClosed);
      }
    };
  }, [])

  const stopVideo = async() => {

    setIniciar(false);
    try {
      let videoId = videoRef.current.srcObject.getTracks()[0];
      videoRef.current.srcObject.removeTrack(videoId);

      const stream = videoRef.current.srcObject;
      const videoTracks = await stream.getVideoTracks();

      videoTracks.forEach(async track => {
        await track.stop();
      });

      // También puedes detener todas las pistas de audio si es necesario
      const audioTracks = stream.getAudioTracks();
      audioTracks.forEach(async track => {
        await track.stop();
      });

      // Detén el objeto de la cámara por completo
      stream.getTracks().forEach(async track => {
        await track.stop();
      });

      videoRef.current.hidden = true;
      videoRef.current.srcObject = null;
      return "video finalizado";
    } catch (error) {
      return error;
    }
  }
  
  return (
    <>
      <Header />
      <Sidebar />
      <main
            id="main"
            className="main"
            style={{ marginLeft: menu ? "" : "0px" }}
        >
            <section className="section">
                <div className="pagetitle">
                    <h1>Mis Asistencias</h1>
                </div>
                <nav>
                  <button
                      className="btn btn-success mt-2 "
                      data-bs-toggle="modal"
                      data-bs-target="#modalAsistencias"
                      onClick={() => setOpenModal(true)}
                  >
                      <i className="bi bi-plus-lg"></i> Agregar Asistencia
                  </button>
                </nav>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Ubicación</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Motivo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {asistencias && asistencias.map((item, index) => (
                                <>
                                <tr key={index}>
                                  <td><a target="_blank"
                                      rel="noreferrer" 
                                      href={`https://www.google.com/maps/place/${item?.latitud}, ${item?.longitud}`}>
                                      <i class='bx bxs-location-plus' ></i>
                                    </a>
                                  </td>
                                  <td>{item?.fecha.split(" ")[0]}</td>
                                  <td>{item?.fecha.split(" ")[1]}</td>
                                  <td>{item?.motivo}</td>
                                </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
        </main>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="modalAsistencias" ref={modalRef} tabIndex="-1" aria-labelledby="exampleModalAsistencias" aria-hidden="true">
            <div className="modal-dialog modal-md" >
            <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalAsistencias">Agregar Asistencia</h5>
                  <button
                  type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <Reconocimiento 
                  openModal={openModal} 
                  stopVideo={stopVideo} 
                  videoRef={videoRef}
                  iniciar={iniciar}
                  setIniciar={setIniciar}
                  />
                </div>
                <div className="modal-footer">
                <button
                type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                >Cerrar</button>
                </div>
            </div>
            </div>
        </div>
      <Footer />
    </>
  )
}
