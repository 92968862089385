import React, { useState, useEffect, useRef } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import useInfo from "../../hooks/useInfo";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const AjustesLogo = () => {
  const { menu } = useMenuToggle();
  const { logo, obtenerLogo } = useInfo();
  const [error, setError] = useState("");
  const [archivo, setArchivo] = useState({});

  const myRefname = useRef(null)
  
  const handleClick = async (e) => {
    e.preventDefault();

    let confirmacion = window.confirm("estas seguro que deseas cambiar el logo?");

    if(confirmacion){
      myRefname.current.click();
    }
  }

  const agregarImagen = async () => {
    
    if(!archivo.name){
      return;
    }

    let formData = new FormData();

    formData.append('files', archivo);

    const URL_API = `${url_api}/api/info/agregar-logo` || "http://localhost:3001/api/info/agregar-logo";
    try {
      const { data } = await axios.post(URL_API, formData);
      obtenerLogo();
      setArchivo({});
      Swal.fire("cambios guardados!", data.message, "success");
      return;
    } catch (error) {

      Swal.fire("ADVERTENCIA!", "Hubo un error al agregar la imagen", "warning");
    }
  }

  useEffect(() => {
    agregarImagen();
  }, [archivo])

  return (
    <>
        <section className="section">
            <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                <img src={logo ? `${url_api}/${logo.logo}` : "aún no hay logo"}/>
                <input ref={myRefname} onChange={(e) => {
                  setArchivo(e.target.files[0]);
                  
                }} type="file" className='form-control d-none'/>
                <button className='btn btn-secondary mt-5'
                  onClick={(e) => handleClick(e)}
                ><i className="bi bi-paperclip"></i></button>
            </div> 
            
        </section>
    </>
  )
}
