import React, { useState, useEffect } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const Correos = () => {
  const { menu } = useMenuToggle();
  const [correo, setCorreo] = useState({});
  const [error, setError] = useState({});
  const [nuevoCorreo, setNuevoCorreo] = useState("");
  const [nuevoPass, setNuevoPass] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [secure, setSecure] = useState("");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(nuevoCorreo === "" || nuevoPass === "" || host === "" || port === "" || secure === ""){
      Swal.fire("ADVERTENCIA!", "Todos los campos son obligatorios.", "warning");
      return;
    }

    const datos = {
      correo: nuevoCorreo,
      pass: nuevoPass,
      host: host,
      port: port,
      secure: secure
    }

    const URL_API = `${url_api}/api/correo/agregar-correo` || "http://localhost:3001/api/correo/agregar-correo";
    try {
      const { data } = await axios.post(URL_API, datos);
      setCorreo(datos);
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el correo", "warning");
    }
  }

  useEffect(() => {
    const obtenerCorreo = async()=> {
      const URL_API = `${url_api}/api/correo/obtener-correo` || "http://localhost:3001/api/correo/obtener-correo";
      try {
        const { data } = await axios.get(URL_API);
        setCorreo(data.data[0])
      } catch (error) {
        setError({ error: "hubo un error"});
      }
    }
    obtenerCorreo();
  }, [])

  useEffect(() => {
    if(correo){
      setNuevoCorreo(correo.correo);
      setNuevoPass(correo.pass);
      setHost(correo.host);
      setPort(correo.port);
      setSecure(correo.secure);
    }
  }, [correo])

  return (
    <>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Email</th>
                          <th scope="col">Password</th>
                          <th scope="col">Host</th>
                          <th scope="col">Port</th>
                          <th scope="col">Secure TLS</th>
                          <th scope="col" colSpan={2}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        {correo ? 
                        
                          <>
                            <td>{correo.correo ? correo.correo : ""}</td>
                            <td>{correo.pass ? correo.pass : ""}</td>
                            <td>{correo.host ? correo.host : ""}</td>
                            <td>{correo.port ? correo.port : ""}</td>
                            <td>{correo.secure ? correo.secure : ""}</td>
                            <td>
                              <button
                                className="btn btn-warning btn-sm"
                                data-bs-toggle="modal" 
                                data-bs-target="#modalCorreo"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                            </td>
                          </>  
                          
                        
                        : 

                          <>
                            <td>No hay información</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                          </> 
                        
                        }
                            
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Modal --> */}
          <div className="modal fade" id="modalCorreo" tabIndex="-1" aria-labelledby="exampleModalCorreo" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCorreo">Modificar Correo</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <label>Indica el Nombre del Servidor</label>
                  <input type='text' className='form-control' value={host} onChange={(e) => setHost(e.target.value)}/>
                  <label>Indica el Correo</label>
                  <input type='text' className='form-control' value={nuevoCorreo} onChange={(e) => setNuevoCorreo(e.target.value)}/>
                  <label>Indica la Contraseña</label>
                  <input type='text' className='form-control' value={nuevoPass} onChange={(e) => setNuevoPass(e.target.value)}/>
                  <label>Indica el Puerto</label>
                  <input type='text' className='form-control' value={port} onChange={(e) => setPort(e.target.value)}/>
                  <label>Seguridad TLS</label>
                  <select className='form-control' onChange={(e) => setSecure(e.target.value)}>
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                  </select>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                  <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Guardar</button>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
