import { useState, useEffect, useRef } from "react";

export const PreguntaAlgebra = ({ content, setDataForm, reset, setReset }) => {

  const [respuesta, setRespuesta] = useState({ respuestas: [] });

  const inputRef = useRef(null);

  useEffect(() => {
    setDataForm(respuesta);
    inputRef.current.value = respuesta.respuestas[0] ?? "";
  }, [respuesta])

  useEffect(() => {
    if(reset){
      inputRef.current.value = "";
      setRespuesta({ respuestas: [] })
      setReset(false);
    }
  }, [reset])

  return (
    <div className='card p-2 bg-secondary col-12 col-md-6 mt-2'>
      <p className='text-center text-white'>Pregunta Algebra</p>
      <h3 className='text-center text-white fw-bold'>Diga el resultado de {content.valorUno + " " + content.tipoOperacion + " " + content.valorDos}</h3>
      <div className='row justify-content-center align-items-center p-2'>
        <input className='form-control' placeholder='ingrese su respuesta'
        ref={inputRef}
        onChange={(e) => setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [ e.target.value ]  })}
        />
      </div>
    </div>
  )
}
