import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import axios from "axios";
import { url_api } from "../../utils/config";
import { deleteImageBiometric, clearInfo, userSelector } from '../../slices/user/userSlice';
import { showAlertNormal, showAlertWithTimer } from "../../utils/showAlert";
import {
  getAllProjects,
} from "../../slices/project/projectSlice";
import Swal from "sweetalert2";

export const Usuarios = () => {
  
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.project);
  const { menu } = useMenuToggle();
  const [usuarios, setUsuarios] = useState([]);
  const [errors, setErrors] = useState({});
  const [proyectosMultiples, setProyectosMultiples] = useState([""]);
  const [previewProject, setPreviewProject] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserDatos, setSelectedUserDatos] = useState({});
  const [userInavilited, setUserInavilited] = useState("");
  const [motivoInhabilitacion, setMotivoInhabilitacion] = useState("");
  const [dniSearch, setDniSearch] = useState("");
  const [data, setData] = useState([]);

  const { status, message, user } = useSelector(userSelector);

  const validateAccess = useCallback(async () => {
    if (status === 1) {
      showAlertWithTimer(message, "", "success");
    } else if (status === 0) {
      showAlertNormal(message, "", "error");
    }
    dispatch(clearInfo());
  }, [status, message]);

  useEffect(() => {
    validateAccess();
  }, [validateAccess]);

  const obtenerUsuarios = async()=> {
      const URL_API = `${url_api}/api/admin/users` || "http://localhost:3001/api/admin/users";
      try {
        const { data } = await axios.get(URL_API);
        setUsuarios(data.data);
      } catch (error) {
        setErrors({ error: "hubo un error"});
      }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(selectedUser === "" || previewProject === "" || proyectosMultiples.length < 1 || selectedUser === undefined || previewProject === undefined){
      Swal.fire("ADVERTENCIA!", "Todos los campos son obligatorios.", "warning");
      return;
    }

    const URL_API = `${url_api}/api/admin/user-project/${selectedUser}` || `http://localhost:3001/api/admin/user-project/${selectedUser}`;
    try {
      const { data } = await axios.put(URL_API, {projectId: proyectosMultiples, previewProject: previewProject});
      await obtenerUsuarios();
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el proyecto", "warning");
    }
  }

  const handleDeleteImage = (id) => {
    Swal.fire({
      title: 'Estas seguro que quieres eliminar la imagen biometrica?',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `No Eliminar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteImageBiometric(id));
      } else if (result.isDenied) {
        Swal.fire('Has decidido no eliminar la imagen', '', 'info');
      }
    })
    
  }

  const handleClick = async(e) => {
    e.preventDefault();
    if(motivoInhabilitacion === ""){
      Swal.fire("ADVERTENCIA!", "El campo mensaje debe estar lleno", "warning");
      return;
    }
    const URL_API = `${url_api}/api/admin/user-disable/${userInavilited}` || `http://localhost:3001/api/admin/user-disable/${userInavilited}`;
    try {
      const { data } = await axios.put(URL_API, { mensaje: motivoInhabilitacion });
      await obtenerUsuarios();
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al inhabilitar el usuario", "warning");
    }
  }

  const reactivarUsuario = async(id) => {
    let confirm = window.confirm("Esta seguro que desea habilitar al usuario?");
    if(confirm){
      const URL_API = `${url_api}/api/admin/user-active/${id}` || `http://localhost:3001/api/admin/user-active/${id}`;
      try {
        const { data } = await axios.put(URL_API);
        await obtenerUsuarios();
        Swal.fire("cambios guardados!", data.message, "success");
      } catch (error) {
        Swal.fire("ADVERTENCIA!", "Hubo un error al inhabilitar el usuario", "warning");
      }
    }
  };

  const limpiarCampos = () => {
    setProyectosMultiples([""]);
    setPreviewProject("");
  }

  useEffect(() => {
    obtenerUsuarios();
  }, [])

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  useEffect(() => {
    if(selectedUser){
      let user = usuarios.find(user => user.id === selectedUser);
      setSelectedUserDatos(user);
    }
  }, [selectedUser])

  useEffect(() => {
    setData(usuarios)
  }, [usuarios])

  useEffect(() => {
    if(dniSearch === ""){
      setData(usuarios);
    }else{
      const asis = usuarios.filter(as => as.dni == dniSearch || as.dni.startsWith(dniSearch) || as.name == dniSearch || as.name.startsWith(dniSearch)); 
      setData(asis);
    }
  }, [dniSearch])

  return (
    <>
    <Header />
    <Sidebar />
    <main
        id="main"
        className="main"
        style={{ marginLeft: menu ? "" : "0px" }}
      >

        <div className="pagetitle">
          <h1>Usuarios Registrados</h1>
        </div>
        <div className="col-12 col-md-4">
              <label>Buscar por DNI o Nombre</label>
              <input className="form-control" placeholder="Ingresa el DNI o Nombre" value={dniSearch} onChange={(e) => setDniSearch(e.target.value)} />
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Nombres</th>
                          <th scope="col">Apellidos</th>
                          <th scope="col">DNI/Cédula</th>
                          <th scope="col">Telf.</th>
                          <th scope="col">Contacto</th>
                          <th scope="col">Telf. Emergencia</th>
                          <th scope="col">Email</th>
                          <th scope="col">Tipo de Sangre</th>
                          <th scope="col">Proyecto</th>
                          <th scope="col">Rol</th>
                          <th scope="col" colSpan={3}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        {data ? 
                        <>
                        {
                          data.map((usuario, index) => (
                            <>
                            {usuario.role.value === "admin" ? "" : 
                            
                            <tr key={index}>
                              <td>{usuario.name}</td>
                              <td>{usuario.lastName}</td>
                              <td>{usuario.dni}</td>
                              <td>{usuario.phone}</td>
                              <td>{usuario.contactEmergency}</td>
                              <td>{usuario.phoneEmergency}</td>
                              <td>{usuario.email}</td>
                              <td>{usuario.typeBlood}</td>
                              <td>{usuario.projects.length > 0 ? 
                              usuario.projects.map((proyecto, index) => (
                                <p key={index}>{index + 1}. {proyecto.name}</p>
                              ))
                              
                              : 
                              
                              ''}</td>
                              <td>{usuario.role.value}</td>

                              {usuario.estado === 1 ?
                              <>
                              <td>
                                <button
                                  className="btn btn-warning btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalReasignacion"
                                  onClick={() => {
                                    limpiarCampos();
                                    setSelectedUser(usuario.id)
                                  }}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>
                                </td>
                                <td>
                                <button
                                  className="btn btn-dark btn-sm"
                                  onClick={() => {
                                    handleDeleteImage(usuario.id);
                                  }}
                                >
                                  <i class='bx bxs-file-image'></i>
                                </button>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalInhabilitar"
                                    onClick={() => { 
                                      limpiarCampos();
                                      setSelectedUser(usuario.id)
                                      setUserInavilited(usuario.id)
                                    }}
                                  >
                                    <i className="bi bi-person-fill-x"></i>
                                  </button>
                                  </td>
                                <td>
                                <button className="btn btn-primary btn-sm ">
                                  <i
                                    className="bi bi-search"
                                    onClick={() => navigation(`/dashboard/usuarios/${usuario.id}`)}
                                  ></i>
                                </button>
                              </td>
                              </>
                              : 

                              <>
                              <td>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => reactivarUsuario(usuario.id)}
                                >
                                  <i className="bi bi-person-fill-up"></i>
                                </button>
                                </td>
                              </>

                              }
                            </tr>

                            
                            }
                            </>
                                                      ))}
                        </>
                          
                        : 

                          <>
                            <td>No hay Registros</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                          </> 
                        
                        }
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Modal Reasignar --> */}
          <div className="modal fade" id="modalReasignacion" tabIndex="-1" aria-labelledby="exampleModalReasignacion" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCorreo">Reasignar Proyecto</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={limpiarCampos}></button>
                </div>
                <div className="modal-body">
                  <p>Usuario <b>{selectedUserDatos ? selectedUserDatos.name + " " + selectedUserDatos.lastName : ""}</b></p>
                  <label>Proyecto a reemplazar</label>
                  <select
                        name="projectId"
                        className="form-control"
                        value={previewProject}
                        onChange={(e) => setPreviewProject(e.target.value)}
                      >
                        <option value="" selected>-- seleccionar --</option>
                        {usuarios.map((proyecto, index) => (
                          <>
                            {selectedUser === proyecto.id && proyecto.projects.length > 0 ? 
                              proyecto.projects.map((project, index) => (
                                <option key={index} value={project.id}>{project.name}</option>
                              ))
                             : ""}
                          </>
                        ))}
                  </select>
                        
                  <label>Selecciona el Nuevo Proyecto</label>
                  <select
                        name="projectId"
                        className="form-control"
                        value={proyectosMultiples[0]}
                        onChange={(e) => {
                            setProyectosMultiples([e.target.value])
                        }}
                      >
                    <option value="" selected>--Seleccionar--</option>
                    {projects ? 
                    
                    <>
                      {projects.map((project, index) => (
                        <>
                          <option key={index} value={project.id}>{project.name} {proyectosMultiples.includes(project.id) ? <a>&#10003;</a> : ""}</option>
                        </>
                      ))}
                    </> 
                    
                    : ""
                    
                    }

                  </select>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={limpiarCampos}>Cerrar</button>
                  <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal">Guardar</button>
                </div>
              </div>
            </div>
          </div>


          {/* <!-- Modal Inhabilitar --> */}
          <div className="modal fade" id="modalInhabilitar" tabIndex="-1" aria-labelledby="exampleModalInhabilitar" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalInhabilitar">Inhabilitar Usuario</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">   
                  <p>Usuario <b>{selectedUserDatos ? selectedUserDatos.name + " " + selectedUserDatos.lastName : ""}</b></p>
                  <label>Motivo de la Inhabilitación</label>
                  <textarea className="form-control"
                  onChange={(e) => setMotivoInhabilitacion(e.target.value)}
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                  <button type="button" className="btn btn-primary" onClick={(e) => handleClick(e)} data-bs-dismiss="modal">Guardar</button>
                </div>
              </div>
            </div>
          </div>

      </main>
    <Footer />
    </>
  )
}
