import React from "react";
import { Link } from "react-router-dom";
import useMenuToggle from "../hooks/useMenuToggle";
import useInfo from "../hooks/useInfo";

export const Footer = () => {
  const { menu } = useMenuToggle();
  const { informacion, titulo } = useInfo();
  return (
    <>
       <footer
        id="footer"
        className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top text-center footer mb-5"
        style={{ marginLeft: menu ? "" : "0px" }}
      >
        <div className="col">
          <Link to="tel:0998113580" className="contact-me">
            <i className="bi bi-phone">&nbsp;</i>
            <span>{informacion ? informacion.telefono : ""}</span>
          </Link>
        </div>
        <div className="col copyright">
          &copy; Copyright&nbsp;
          <Link to="/dashboard/projects"><span className="fw-bold">{titulo ? titulo.titulo : ""}</span></Link>. Todos los Derechos Reservados
        </div>
        <div className="col">
          <Link to="mailto:fnicolalde@mslaps.com" className="contact-me">
            <i className="bi bi-envelope-at">&nbsp;</i>
            <span>{informacion ? informacion.correo : ""}</span>
          </Link>
        </div>
      </footer>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
