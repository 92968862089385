import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from 'react-router-dom';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";
import { ChatArea } from './ChatArea';

export const SendMessage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.project);
  const { menu } = useMenuToggle();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const URL_API = `${url_api}/api/admin/user-project/` || `http://localhost:3001/api/admin/user-project}`;
    try {
      const { data } = await axios.put(URL_API);
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el proyecto", "warning");
    }
  }

  return (
    <>
    <Header />
    <Sidebar />
    <main
        id="main"
        className="main"
        style={{ marginLeft: menu ? "" : "0px" }}
      >

        <div className="pagetitle">
          <h1>Envio de Mensajes</h1>
          <div className='mt-3 col-12'>
            <ChatArea/>
          </div>
        </div>
      </main>
    <Footer />
    </>
  )
}
