import React from "react";
import { Contratistas } from "./components/contratistas/Contratistas";
import { Operarios } from "./components/operarios/Operarios";
import { OtrosOperarios } from "./components/operarios/OtrosOperarios";
import { Login } from "./components/login/Login";
import { RecoveryPassword } from "./components/recovery/RecoveryPassword";
import { Routes, Route } from "react-router-dom";
import { Supervisores } from "./components/supervisores/Supervisores";
import { Projects } from "./components/projects/Projects";
import { Cargos } from "./components/cargos/Cargos";
import { Profile } from "./components/profile/Profile";
import { GenerarQR } from "./components/generarQR/GenerarQR";
import { NotFound } from "./components/notfound/NotFound";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { userSelector } from "./slices/user/userSlice";
import { useSelector } from "react-redux";
import { Certificates } from "./components/certificates/Certificates";
import { Correos } from "./components/correos/Correos";
import { Ajustes } from "./components/ajustes/Ajustes";
import { Usuarios } from "./components/usuarios/Usuarios";
import { Historial } from "./components/historial/Historial";
import { CertificatesUser } from "./components/certificates/CertificatesUser";
import { SendMessage } from "./components/messages/SendMessage";
import { CertificadosFijos } from "./components/certificadosFijos/CertificadosFijos";
import { Evaluaciones } from "./components/evaluaciones/Evaluaciones";
import { EvaluacionesUser } from "./components/evaluacionesUser/EvaluacionesUser";
import { Evaluacion } from "./components/evaluacionesUser/Evaluacion";
import { AreaEvaluacion } from "./components/evaluaciones/AreaEvaluacion";
import { AllAsistencias } from "./components/asistencias/AllAsistencias";
import { UserAsistencias } from "./components/asistencias/UserAsistencias";

function App() {
  const { user, isLogin } = useSelector(userSelector);
  return (
    <div className="App" id="appbg">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/recovery-password/:id" element={<RecoveryPassword />} />
        <Route element={<ProtectedRoute isLogin={isLogin} />}>
          <Route path="/dashboard/operarios" element={<Operarios />} />
          <Route path="/dashboard/operarios-proyectos" element={<OtrosOperarios />} />
          <Route path="/dashboard/certificates" element={<Certificates />} />
          <Route path="/dashboard/evaluaciones-user" element={<EvaluacionesUser />} />
          <Route path="/dashboard/evaluaciones-user/:id" element={<Evaluacion />} />
          <Route path="/dashboard/asistencias-usuario" element={<UserAsistencias />} />
          <Route
            path="/dashboard/certificates/:idUser"
            element={<CertificatesUser />}
          />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/generateqr" element={<GenerarQR />} />
          <Route path="/dashboard/send-messages" element={<SendMessage />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              isLogin={isLogin && user.role?.value === "admin"}
              redirectTo="/dashboard/operarios"
            />
          }
        >
          <Route path="/dashboard/projects" element={<Projects />} />
          <Route path="/dashboard/cargos" element={<Cargos />} />
          <Route path="/dashboard/usuarios" element={<Usuarios />} />
          <Route path="/dashboard/usuarios/:id" element={<Historial />} />
          <Route path="/dashboard/supervisores" element={<Supervisores />} />
          <Route path="/dashboard/contratistas" element={<Contratistas />} />
          <Route path="/dashboard/ajustes" element={<Ajustes />} />
          <Route path="/dashboard/certificados-fijos" element={<CertificadosFijos />} />
          <Route path="/dashboard/evaluaciones" element={<AreaEvaluacion />} />
          <Route path="/dashboard/asistencias" element={<AllAsistencias />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
