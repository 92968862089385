import React, { useEffect, useState } from 'react'

export const PreguntaEnumeracion = ({ content, setDataForm, reset, setReset }) => {

  const [respuestas, setRespuestas] = useState([]);

  const [respuesta, setRespuesta] = useState({ respuestas: [] });

  function desordenarArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  useEffect(() => {
    setDataForm(respuesta);
  }, [respuesta])

  useEffect(() => {
    let previewRespuestas = desordenarArray([content.respuesta1, content.respuesta2, content.respuesta3, content.respuesta4]);
    setRespuestas(previewRespuestas);
  }, [content])

  useEffect(() => {
    if(reset){
      setRespuesta({ respuestas: [] })
      setReset(false);
    }
  }, [reset])

  return (
    <div className='card p-2 bg-secondary col-12 col-md-6 mt-2'>
      <p className='text-center text-white'>Pregunta Enumeración</p>
      <h3 className='text-center text-white fw-bold'>{content.pregunta}</h3>
      <div className='row justify-content-center align-items-center p-2'>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(respuestas[0])){
              let res = respuesta.respuestas.filter(item => item !== respuestas[0]);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: res  })
            }else{
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, respuestas[0]]  })
            }
          } }
        >
          {respuestas[0]} {respuesta?.respuestas?.includes(respuestas[0]) ? respuesta?.respuestas?.findIndex(item => item == respuestas[0]) + 1 : ""}
        </button>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(respuestas[1])){
              let res = respuesta.respuestas.filter(item => item !== respuestas[1]);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: res  })
            }else{
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, respuestas[1]]  })
            }
          } }
        >
          {respuestas[1]} {respuesta?.respuestas?.includes(respuestas[1]) ? respuesta?.respuestas?.findIndex(item => item == respuestas[1]) + 1 : ""}
        </button>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(respuestas[2])){
              let res = respuesta.respuestas.filter(item => item !== respuestas[2]);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: res  })
            }else{
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, respuestas[2]]  })
            }
          } }
        >
          {respuestas[2]} {respuesta?.respuestas?.includes(respuestas[2]) ? respuesta?.respuestas?.findIndex(item => item == respuestas[2]) + 1 : ""}
        </button>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(respuestas[3])){
              let res = respuesta.respuestas.filter(item => item !== respuestas[3]);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: res  })
            }else{
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, respuestas[3]]  })
            }
          } }
        >
          {respuestas[3]} {respuesta?.respuestas?.includes(respuestas[3]) ? respuesta?.respuestas?.findIndex(item => item == respuestas[3]) + 1 : ""}
        </button>
      </div>
    </div>
  )
}
