import React, { useState, useEffect } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import useInfo from "../../hooks/useInfo";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const CertificadosFijos = () => {
  const { menu } = useMenuToggle();
  const { certificados, obtenerCertificados } = useInfo();
  const [error, setError] = useState("");
  const [modoEdicion, setModoEdicion] = useState(false);
  const [nombrecertificado, setNombreCertificado] = useState("");
  const [obligatorio, setObligario] = useState(false);
  const [certificadoSelected, setCertificadoSelected] = useState({});
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(nombrecertificado === ""){
      Swal.fire("ADVERTENCIA!", "Debes llenar todos los campos", "warning");
      return;
    }
    
    if(modoEdicion){
      const URL_API = `${url_api}/api/certificate/certificate-fixed/${certificadoSelected.id}` || `http://localhost:3001/api/certificate-fixed/${certificadoSelected.id}`;
      try {
        const { data } = await axios.put(URL_API, { nombrecertificado, obligatorio });
        obtenerCertificados();
        Swal.fire("cambios guardados!", data.message, "success");
      } catch (error) {
        Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el certificado", "warning");
      }
    }else{
      const URL_API = `${url_api}/api/certificate/certificate-fixed` || "http://localhost:3001/api/certificate/certificate-fixed";
      try {
        const { data } = await axios.post(URL_API, { nombrecertificado, obligatorio });
        obtenerCertificados();
        Swal.fire("cambios guardados!", data.message, "success");
      } catch (error) {
        Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el certificado", "warning");
      }
    }
  }

  const eliminarCertificado = async(id)=> {

    const confirm = window.confirm("estas seguro que deseas eliminar el certificado?");

    if(confirm){
      const URL_API = `${url_api}/api/certificate/certificate-fixed/${id}` || `http://localhost:3001/api/certificate-fixed/${id}`;
      try {
        const { data } = await axios.delete(URL_API);
        obtenerCertificados();
        Swal.fire("cambios guardados!", data.message, "success");
      } catch (error) {
        Swal.fire("ADVERTENCIA!", "Hubo un error al eliminar el certificado", "warning");
      }
    }
  }

  useEffect(() => {
    obtenerCertificados();
  }, [])

  return (
    <>
    <Header />
      <Sidebar />
      <main
        id="main"
        className="main"
        style={{ marginLeft: menu ? "" : "0px" }}
      >
        <section className="section">
        <div className="pagetitle">
            <h1>Certificados Fijos</h1>
            <nav>
              <button
                className="btn btn-success mt-2 "
                data-bs-toggle="modal"
                data-bs-target="#modalCertificado"
                onClick={() => { 
                  setCertificadoSelected({})
                  setModoEdicion(false);
                  setNombreCertificado("");
                }}
              >
                <i className="bi bi-plus-lg"></i> Crear Nuevo
              </button>

            </nav>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Nombre del Certificado</th>
                          <th scope="col">Obligatorio</th>
                          <th scope="col">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        {certificados.length > 0 ? 
                          <>
                          {certificados.map((certificado, index) => (
                            <>
                            <tr key={index}>
                              <td>{certificado.nombrecertificado}</td>
                              <td>{certificado.obligatorio === true ? "Si" : "No"}</td>
                              <td>
                                <button
                                  className="btn btn-warning btn-sm me-2"
                                  data-bs-toggle="modal" 
                                  data-bs-target="#modalCertificado"
                                  onClick={() => {
                                    setCertificadoSelected(certificado)
                                    setModoEdicion(true);
                                    setNombreCertificado(certificado.nombrecertificado);
                                  }}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => eliminarCertificado(certificado.id)}
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </button>
                              </td>
                            </tr>
                            </>
                            ))}
                            
                          </>  
                          
                        
                        : 

                          <>
                          <tr>
                            <td>No hay información</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr> 
                          </> 
                        
                        }
                            
                        
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Modal --> */}
          <div className="modal fade" id="modalCertificado" tabIndex="-1" aria-labelledby="exampleModalCertificado" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCertificado">{modoEdicion ? "Editar Certificado Fijo" : "Agregar Certificado Fijo"}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <label>Nombre del Certificado</label>
                    <input type='text' className='form-control' value={nombrecertificado}
                    onChange={(e) => setNombreCertificado(e.target.value)}
                    />

                    <label>Obligatorio</label>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                      onClick={() => setObligario(true)}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                          Si
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={obligatorio === false ? true : false} 
                      onClick={() => setObligario(false)}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                          No
                      </label>
                    </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                  onClick={(e) => handleSubmit(e)}
                  >Guardar</button>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
    </>
  )
}
