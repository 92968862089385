import React, { useEffect, useState } from "react";
import useInfo from "../../hooks/useInfo";
import { Certificate } from "./Certificate";
import { validateDocuments } from "../../utils/validation";


export const Certificates = ({ count, setInput, input, errors, setInputsCertificates, inputsCertificates }) => {

  const { certificados } = useInfo();
  const [certificadosFijos, setCertificadosFijos] = useState([]);
  const [certificadosFijosInfo, setCertificadosFijosInfo] = useState([]);

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = name === "expiration" || name === "nameArchivo" ? e.target.value : e.target.files[0];
    const newInput = [...inputsCertificates];
    newInput[i] = { ...newInput[i], [name]: value, obligatorio: e.target.required };
    setInputsCertificates(newInput);
  };

  const arrayInput = [];
  for (let index = 0; index < count; index++) {
    let nombreCertificado = "";
    let required = true;
    if(certificadosFijos.length === count){
      nombreCertificado = certificadosFijos[index].nombrecertificado;
      required = certificadosFijos[index].obligatorio
    }
    arrayInput.push(
      <Certificate key={index} onChange={(e) => handleChange(e, index)} value={nombreCertificado} indice={index} setCertificadosFijosInfo={setCertificadosFijosInfo} required={required}/>
    );
  }

  useEffect(() => {
    setInput({
      ...input,
      certificates: inputsCertificates,
    });
  }, [inputsCertificates]);

  useEffect(() => {
    if(certificados.length === count){
      setCertificadosFijos(certificados);
    }else{
      setCertificadosFijos([]);
    }
  }, [count])

  useEffect(() => {
    if(certificadosFijosInfo.length > 0){
      setInputsCertificates(certificadosFijosInfo);
    }else{
      setInputsCertificates([{ nameArchivo: "", obligatorio: true }]);
    }
  }, [certificadosFijosInfo])

  return (
    <div className="col">
      <label className="form-label">Certificados</label>
      <div className="d-flex justify-content-around mb-2">
        <div className="col-3 me-1 mb-2">
          <span className="text-danger">Nombre del Archivo</span>
        </div>
        <div className="col-3 me-1 mb-2">
          <span className="text-danger">Obligatorio</span>
        </div>
        <div className="col-3 me-1 mb-2 text-center">
          <span className="text-danger">Tamaño de archivo max. 1MB</span>
        </div>
        <div className="col-3 me-1 mb-2 text-center">
          <span className="text-primary">Fecha de Expiración</span>
        </div>
      </div>
      {arrayInput}
      {validateDocuments(inputsCertificates).name ? 
      
        <div
          className="alert alert-danger alert-dismissible fade show my-2"
          role="alert"
        >
          <span>{validateDocuments(inputsCertificates).name}</span>
        </div>
       : ""}
       {validateDocuments(inputsCertificates).nameArchivo ? 
      
      <div
        className="alert alert-danger alert-dismissible fade show my-2"
        role="alert"
      >
        <span>{validateDocuments(inputsCertificates).nameArchivo}</span>
      </div>
     : ""}
    {validateDocuments(inputsCertificates).expiration ? 
      
      <div
        className="alert alert-danger alert-dismissible fade show my-2"
        role="alert"
      >
        <span>{validateDocuments(inputsCertificates).expiration}</span>
      </div>
     : ""}
    </div>
  );
};
