import React, { useState, useEffect, useCallback } from "react";
import Places from "../map/MapGoogle";
import Mapas from "../mapas/Mapas";
import useInfo from "../../hooks/useInfo";
export const Form = (props) => {
  const {
    handleChange,
    handleSubmit,
    input,
    setInput,
    action,
    errors,
    setErrors,
    mapa,
    direccion,
    setDireccion,
    coordinatesDireccion,
    setCoordinatesDireccion,
    position,
    setPosition,
  } = props;

  const { certificados } = useInfo();
  const [certificadoDinamico, setCertificadoDinamico] = useState(certificados.length > 1 ? certificados.length : 1);
  const [selectedDinamico, setSelectedDinamico] = useState(false);

  useEffect(() => {
    setInput({ ...input, ['totalCertificates']: certificadoDinamico});
  }, [certificadoDinamico, selectedDinamico])

  return (
    <div className="modal fade" id="verticalycentered" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {action && action === "create"
                ? "Agregar Proyecto"
                : "Editar Proyecto"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="row g-3" onSubmit={(e) => {
              e.preventDefault();
              setCertificadoDinamico(false)
              handleSubmit(e)
              }}>
              <div className="col-12">
                <label htmlFor="name" className="form-label">
                  Nombre del Proyecto
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  id="name"
                  value={input.name}
                />
                {errors.name && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.name}</span>
                  </div>
                )}
              </div>

              <div className="col-12">
                <label htmlFor="location" className="form-label">
                  Ubicación del Proyecto
                </label>
                <Mapas 
                    mapa={mapa}
                    direccion={direccion}
                    setDireccion={setDireccion}
                    coordinatesDireccion={coordinatesDireccion}
                    setCoordinatesDireccion={setCoordinatesDireccion}
                    position={position}
                    setPosition={setPosition}
                  />
                
                {errors.location && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.location}</span>
                  </div>
                )}
              </div>

              <div className="col-12">
                <label htmlFor="dateStart" className="form-label">
                  Fecha de Inicio
                </label>
                <input
                  type="date"
                  name="dateStart"
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  id="dateStart"
                  value={input.dateStart}
                />
                {errors.dateStart && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.dateStart}</span>
                  </div>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="dateEnd" className="form-label">
                  Fecha de Finalización
                </label>
                <input
                  type="date"
                  name="dateEnd"
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  id="dateEnd"
                  value={input.dateEnd}
                />
                {errors.dateEnd && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.dateEnd}</span>
                  </div>
                )}
              </div>
              <div className="col-12">
              <label>Tipo de Certificados</label>
                <div className="form-check">
                  <input className="form-check-input" checked={selectedDinamico === false ? true : false} type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  onClick={() => {
                    setSelectedDinamico(false)
                    setCertificadoDinamico(certificados.length > 1 ? certificados.length : 1)}}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Fijo
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  
                  onClick={(e) => setSelectedDinamico(true)}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Dinamico
                  </label>
                </div>
                <div className={selectedDinamico ? "d-block" : "d-none"}>
                  <label htmlFor="totalCertificates" className="form-label">
                    N° Certificados
                  </label>
                  <input
                    type="number"
                    name="totalCertificates"
                    onChange={(e) => setCertificadoDinamico(e.target.value)}
                    className="form-control"
                    id="totalCertificates"
                    min={1}
                  />
                </div>
                {errors.totalCertificates && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.totalCertificates}</span>
                  </div>
                )}
              </div>
              
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  disabled={
                    Object.keys(errors).length > 0 || input.name === ""
                      ? true
                      : false
                  }
                >
                  Guardar
                </button>

                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
