import React, { useState, useEffect, } from 'react';
import Completacion from './Completacion';
import Enumeracion from './Enumeracion';
import RespuestaBreve from './RespuestaBreve';
import SeleccionMultiple from './SeleccionMultiple';
import Algebra from './Algebra';
import useInfo from "../../hooks/useInfo";
import { validateEvaluations } from '../../utils/validation';
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import {
  getAllUsers,
} from "../../slices/userCrudSlice/userCrudSlice";
import { crearEvaluacion, editarEvaluacion, eliminarEvaluacion } from '../../slices/evaluacion/evaluacion';
import { useDispatch, useSelector } from "react-redux";

export const Evaluaciones = () => {

  const { evaluaciones, setEvaluaciones, obtenerEvaluaciones, roles } = useInfo();

  const { users } = useSelector((state) => state.userCrud);
  const dispatch = useDispatch();

  const [preguntas, setPreguntas] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [typeQuestion, setTypeQuestion] = useState("");
  const [typeQuestions, setTypeQuestions] = useState([]);
  const [tipoOperacion, setTipoOperacion] = useState("");
  const [cantidadPreguntas, setCantidadPreguntas] = useState(0);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [input, setInput] = useState({});
  const [usuariosSelected, setUsuariosSelected] = useState([]);
  const [errors, setErrors] = useState([])

  const [files, setFiles] = useState([]);

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };

  const handleClick = async(e) => {
    e.preventDefault();

    const formData = new FormData();
    const data = { ...input };
    delete data.evaluationUsers;
    delete data.evaluationBreves;
    delete data.evaluationAlgebras;
    delete data.evaluationMultiples;
    delete data.evaluationEnumerates;
    delete data.evaluationCompletations;
    formData.append("evaluation", JSON.stringify({
      ...data, 
      cantQuest: preguntas.length,
      usuarios: usuariosSelected,
     }));
    
    if(preguntas.length > 0){
      preguntas.forEach(el => {
        const { tipo } = el;
        formData.append(tipo, JSON.stringify(el));
      })
    }
    if(files){
      formData.append("video", files[0]?.file);
    }

    if(modoEdicion){
      dispatch(editarEvaluacion(formData));
    }else{
      dispatch(crearEvaluacion(formData));
    }
    setTimeout(() => {
      obtenerEvaluaciones();
    }, 2000)
  }

  const handleDelete = async(id) => {
    dispatch(eliminarEvaluacion(id));
    setTimeout(() => {
      obtenerEvaluaciones();
    }, 2000)
  } 

  const clearInfo = () => {
    setModoEdicion(false);
    setCantidadPreguntas(0);
    setPreguntas([]);
    setTypeQuestions([]);
    setTypeQuestion("");
    setInput({ 
      nombre: "", 
      tipo: "", 
      descripcion: "", 
      puntuacion: "", 
      calMin: "", 
      numInt: "", 
      time: "", 
      url: "" 
    });
    setUsuariosSelected([]);
  }

  const preguntasInputs = Array.from({ length: cantidadPreguntas }, (_, index) => (
    <>
      {typeQuestions[index] == "multiple" ?
        <SeleccionMultiple 
        setPreguntas={setPreguntas} 
        preguntas={preguntas} 
        index={index} 
        typeQuestions={typeQuestions}/>
        :
        ""
      }
      {typeQuestions[index] == "enumeracion" ?
        <Enumeracion 
        setPreguntas={setPreguntas} 
        preguntas={preguntas} 
        index={index} 
        typeQuestions={typeQuestions}/>
        :
        ""
      }
      {typeQuestions[index] == "breve" ?
        <RespuestaBreve
        setPreguntas={setPreguntas} 
        preguntas={preguntas} 
        index={index} 
        typeQuestions={typeQuestions}/>
        :
        ""
      }
      {typeQuestions[index] == "completacion" ?
        <Completacion
        setPreguntas={setPreguntas} 
        preguntas={preguntas} 
        index={index} 
        typeQuestions={typeQuestions}/>
        :
        ""
      }
      {typeQuestions[index] == "algebra" ?
        <Algebra
        setPreguntas={setPreguntas} 
        preguntas={preguntas} 
        index={index} 
        typeQuestions={typeQuestions}/>
        :
        ""
      }
    </>
  ));

  const agregarPreguntasEdit = (evaluacion) => {
    let i = 0;
    let previewQuestions = [];
    if(evaluacion.evaluationAlgebras.length > 0){
      evaluacion.evaluationAlgebras.forEach(item => {
        typeQuestions[i] = "algebra";
        previewQuestions[i] = { 
          valorUno: item.valorUno, 
          valorDos: item.valorDos,
          tipoOperacion: item.tipoOperacion,
          preguntaId: item.id,
          indice: i,
          tipo: "algebra"
        }
        i++;
      })
    }
    if(evaluacion.evaluationBreves.length > 0){
      evaluacion.evaluationBreves.forEach(item => {
        typeQuestions[i] = "breve";
        previewQuestions[i] = { 
          pregunta: item.pregunta,
          respuesta: item.respuesta,
          preguntaId: item.id,
          indice: i,
          tipo: "breve"
        };
        i++;
      })
    }
    if(evaluacion.evaluationCompletations.length > 0){
      evaluacion.evaluationCompletations.forEach(item => {
        typeQuestions[i] = "completacion";
        previewQuestions[i] = { 
          pregunta: item.pregunta, 
          respuesta: item.respuesta,
          preguntaId: item.id,
          indice: i,
          tipo: "completacion"
        }
        i++;
      })
    }
    if(evaluacion.evaluationEnumerates.length > 0){
      evaluacion.evaluationEnumerates.forEach(item => {
        typeQuestions[i] = "enumeracion";
        previewQuestions[i] = { 
          pregunta: item.pregunta, 
          respuesta1: item.respuesta1,
          respuesta2: item.respuesta2,
          respuesta3: item.respuesta3,
          respuesta4: item.respuesta4,
          preguntaId: item.id,
          indice: i,
          tipo: "enumeracion"
        }
        i++;
      })
    }
    if(evaluacion.evaluationMultiples.length > 0){
      evaluacion.evaluationMultiples.forEach(item => {
        typeQuestions[i] = "multiple";
        previewQuestions[i] = { 
          pregunta: item.pregunta, 
          respuesta1: item.respuesta1,
          respuesta2: item.respuesta2,
          respuesta3: item.respuesta3,
          respuesta4: item.respuesta4,
          r1: item.r1,
          r2: item.r2,
          r3: item.r3,
          r4: item.r4,
          preguntaId: item.id,
          indice: i,
          tipo: "multiple"
        }
        i++;
      })
    }
    setCantidadPreguntas(i);
    setPreguntas(previewQuestions);
  }

  useEffect(() => {
    if(tipoOperacion == "agregar"){
      typeQuestions[cantidadPreguntas-1] = typeQuestion;
      setTypeQuestions(typeQuestions);
      setTypeQuestion("");
    }else if(tipoOperacion == "eliminar"){
      typeQuestions.pop();
      preguntas.pop();
      setPreguntas(preguntas);
      setTypeQuestions(typeQuestions);
    }
  }, [tipoOperacion, cantidadPreguntas])

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    setUsersData(users)
  }, [users])

  useEffect(() => {
    if(userSearch === ""){
      setUsersData(users);
    }else{
      const usuarios = users.filter(user => user.dni == userSearch || user.dni.startsWith(userSearch) || user.name == userSearch ||user.name.startsWith(userSearch));
      setUsersData(usuarios);
    }
  }, [userSearch])

  useEffect(() => {
    let errors = validateEvaluations(input, usuariosSelected, preguntas);
    setErrors(Object.values(errors))
  }, [input, usuariosSelected, preguntas])

  useEffect(() => {
    setData(evaluaciones)
  }, [evaluaciones])

  useEffect(() => {
    if(search === ""){
      setData(evaluaciones);
    }else{
      const asis = evaluaciones.filter(as => as.nombre == search || as.nombre.startsWith(search)); 
      setData(asis);
    }
  }, [search])

  return (
    <>
        <section className="section mt-5">
            <div className="pagetitle">
                <h1>Evaluaciones</h1>
                <nav className='d-flex flex-column flex-md-row justify-content-between'>
                  <button
                      className="btn btn-success mt-2 "
                      data-bs-toggle="modal"
                      data-bs-target="#modalEvaluaciones"
                      onClick={() => clearInfo()}
                  >
                      <i className="bi bi-plus-lg"></i> Crear Nuevo
                  </button>

                  <div className="col-12 col-md-4">
                    <label>Buscar</label>
                    <input className="form-control" placeholder="Ingresa el nombre de la Evaluación" value={search} onChange={(e) => setSearch(e.target.value)} />
                  </div>
                </nav>
                
            </div>

            <div className="row">
        <div className="col-lg-12">
            <div className="card">
            <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Nombre evaluación</th>
                        <th scope="col">Usuarios a Evaluar</th>
                        <th scope="col">Puntuación</th>
                        <th scope="col">Cal. minima</th>
                        <th scope="col">Num. intentos</th>
                        <th scope="col">Cant. preguntas</th>
                        <th scope="col">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.length > 0 ? 
                        <>
                        {data.map((evaluacion, index) => (
                        <>
                        <tr key={index}>
                            <td>{evaluacion.nombre}</td>
                            <td>
                            {evaluacion?.evaluationUsers?.map((item, index) => (
                            <tr>{(index+1) + ". " + item?.user?.name + " " + item?.user?.lastName + " "}<b>{item?.user?.role?.value}</b></tr>
                            )) 
                            }</td>
                            <td>{evaluacion.puntuacion}</td>
                            <td>{evaluacion.calMin}</td>
                            <td>{evaluacion.numInt}</td>
                            <td>{evaluacion.cantQuest}</td>
                            <td>
                            <button
                                className="btn btn-warning btn-sm me-2"
                                data-bs-toggle="modal" 
                                data-bs-target="#modalEvaluaciones"
                                onClick={() => {
                                setInput(evaluacion);
                                agregarPreguntasEdit(evaluacion);
                                let users = evaluacion.evaluationUsers.map(user => user.userId);
                                setUsuariosSelected(users);
                                setModoEdicion(true);
                                }}
                            >
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(evaluacion.id)}
                            >
                                <i className="bi bi-trash-fill"></i>
                            </button>
                            </td>
                        </tr>
                        </>
                        ))}
                        
                        </>  
                        
                    
                    : 

                        <>
                        <tr>
                        <td>No hay información</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr> 
                        </> 
                    
                    }
                    
                    </tbody>
                </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="modalEvaluaciones" tabIndex="-1" aria-labelledby="exampleModalEvaluacion" aria-hidden="true">
            <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalEvaluacion">{modoEdicion ? "Editar Evaluación" : "Agregar Evaluación"}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className='d-flex flex-column flex-md-row gap-3'>
                    <div className='col-12 col-md-6'>
                    <label>Nombre de la Evaluación</label>
                    <input type='text' className='form-control' value={input?.nombre}
                    placeholder='Nombre de la evaluación'
                    onChange={(e) => setInput({...input, nombre: e.target.value})}
                    />
                    {!input?.nombre && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).nombre}</span>
                      </div>
                    )}
                    <label>Usuarios a Evaluar</label>
                    <div className='d-flex justify-content-center align-items-center mb-2 gap-2'>
                      <label>buscar un usuario</label>
                      <input className='form-control col' placeholder='Ingresa un nombre o dni' value={userSearch} onChange={(e) => setUserSearch(e.target.value)}/>
                    </div>
                    <select multiple className='form-control'
                    onChange={(e) => {
                        if(!usuariosSelected.includes(e.target.value)){
                        if(e.target.value == "") return;
                        setUsuariosSelected([...usuariosSelected, e.target.value])
                        }else{
                        const resultado = usuariosSelected.filter(user => user != e.target.value);
                        setUsuariosSelected(resultado);
                        }
                    }}
                    >
                        <option value="" selected>Seleccione una opción</option>
                        {usersData && usersData.map((user, index) => (
                        <>
                        {user.role.value !== "admin" && user.estado !== 0 ? <option key={index} value={user.id}>{user.name + " " + user.lastName + " " + user.role.value} {usuariosSelected?.includes(user.id) ? <a>&#10003;</a> : ""}</option> : ""}
                        </>
                        ))}
                    </select>
                    {usuariosSelected.length < 1 && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).usuarios}</span>
                      </div>
                    )}

                    <label>Descripción de la Evaluación</label>
                    <textarea className='form-control' value={input?.descripcion}
                    placeholder='Descripción de la evaluación'
                    onChange={(e) => setInput({...input, descripcion: e.target.value})}
                    />
                    {!input?.descripcion && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).descripcion}</span>
                      </div>
                    )}
                    </div>
                    <div className='col-12 col-md-6'>
                    <label>Puntuación</label>
                    <input type='number' className='form-control' value={input?.puntuacion}
                    placeholder='Puntuación'
                    onChange={(e) => setInput({...input, puntuacion: e.target.value})}
                    />
                    {!input?.puntuacion && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).puntuacion}</span>
                      </div>
                    )}
                    <label>Calificación minima</label>
                    <input type='number' className='form-control' value={input?.calMin}
                    placeholder='Calificación minima'
                    onChange={(e) => setInput({...input, calMin: e.target.value})}
                    />
                    {!input?.calMin && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).calMin}</span>
                      </div>
                    )}
                    <label>Numero Max. de Intentos</label>
                    <input type='number' className='form-control' value={input?.numInt}
                    placeholder='Numero max. de intentos'
                    onChange={(e) => setInput({...input, numInt: e.target.value})}
                    />
                    {!input?.numInt && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).numInt}</span>
                      </div>
                    )}
                    <label>Tiempo Max. de la Evaluación en Minutos</label>
                    <input type='number' className='form-control' value={input?.time}
                    placeholder='Tiempo máximo'
                    onChange={(e) => setInput({...input, time: e.target.value})}
                    />
                    {!input?.time && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).time}</span>
                      </div>
                    )}
                    </div>
                </div>
                    <p className='mt-3'>Agregar Pregunta Nueva</p>
                    <div className='d-flex gap-3'>
                    <button className='btn btn-warning' 
                    onClick={() => {
                        if(cantidadPreguntas === 1) return
                        setCantidadPreguntas(cantidadPreguntas-1)
                        setTipoOperacion("eliminar");
                    }}><i class='bx bx-x'></i>
                    </button>
                    <select className='form-control'
                    value={typeQuestion}
                    onChange={(e) => setTypeQuestion(e.target.value)}>
                        <option value="" selected>--seleccionar --</option>
                        <option value="multiple">Seleccion multiple</option>
                        <option value="breve">Respuesta breve</option>
                        <option value="completacion">Completación</option>
                        <option value="enumeracion">Enumeración</option>
                        <option value="algebra">Algebra</option>
                    </select>
                    <button className='btn btn-primary' 
                    onClick={() => {
                        setCantidadPreguntas(cantidadPreguntas+1)
                        setTipoOperacion("agregar");
                        }}><i class='bx bx-plus'></i>
                    </button>
                    </div>
                    <div className='d-flex flex-column gap-4 mt-3'>
                    <div style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                        {preguntasInputs}
                    </div>
                    </div>
                    {preguntas.length < 1 && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{validateEvaluations(input, usuariosSelected, preguntas).preguntas}</span>
                      </div>
                    )}
                    <div className='d-flex flex-column'>
                      <Dropzone onChange={updateFiles} value={files}>
                      {files.map((file) => (
                          <FileMosaic {...file} preview />
                      ))}
                      </Dropzone>
                    <span>Max. tamaño del video 50mb.</span>
                    </div>

                    <label className='mt-3'>Enlace de Video Externo</label>
                    <input type='text' className='form-control' value={input?.url}
                    placeholder='Enlace de video externo'
                    onChange={(e) => setInput({...input, url: e.target.value})}
                    />

                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                disabled={errors.length > 0 ? true : false}
                onClick={(e) => handleClick(e)}
                >Guardar</button>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}
