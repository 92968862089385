import React, { useState, useEffect } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import useInfo from "../../hooks/useInfo";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const AjustesInformacion = () => {
  const { menu } = useMenuToggle();
  const { informacion, obtenerInformacion } = useInfo();
  const [error, setError] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(telefono === "" || correo === ""){
      Swal.fire("ADVERTENCIA!", "todos los campos son obligatorios", "warning");
      return;
    }

    const URL_API = `${url_api}/api/info/agregar-informacion` || "http://localhost:3001/api/info/agregar-informacion";
    try {
      const { data } = await axios.post(URL_API, { telefono: telefono, correo: correo});
      obtenerInformacion();
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el correo", "warning");
    }
  }

  return (
    <>
        <section className="section">
            <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">telefono</th>
                          <th scope="col">correo</th>
                          <th scope="col" colSpan={2}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        {informacion ? 
                        
                          <>
                            <td>{informacion.telefono ? informacion.telefono  : ""}</td>
                            <td>{informacion.correo ? informacion.correo  : ""}</td>
                            <td>
                              <button
                                className="btn btn-warning btn-sm"
                                data-bs-toggle="modal" 
                                data-bs-target="#modalInformacion"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                            </td>
                          </>  
                        
                        : 

                          <>
                            <td>No hay información</td>
                            <td></td>
                            
                          </> 
                        
                        }
                            
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="modalInformacion" tabIndex="-1" aria-labelledby="exampleModalCorreo" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCorreo">Modificar Información</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <label>Indica el Nuevo Teléfono</label>
                  <input type='text' className='form-control'
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                  <label>Indica el Nuevo Correo</label>
                  <input type='email' className='form-control'
                    onChange={(e) => setCorreo(e.target.value)}
                  />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                  <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Guardar</button>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
