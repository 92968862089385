import { useState } from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import { Resultados } from "./Resultados";
import { Evaluaciones } from "./Evaluaciones";

export const AreaEvaluacion = () => {

  const { menu } = useMenuToggle();

  const [changeView, setChangeView] = useState("evaluaciones");

  return (
    <>
    <Header />
      <Sidebar />
        <main
            id="main"
            className="main"
            style={{ marginLeft: menu ? "" : "0px" }}
        >
            <section className="section">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-primary"
                    onClick={() => setChangeView("evaluaciones")}
                  >Evaluaciones</button>
                  <button type="button" class="btn btn-primary"
                    onClick={() => setChangeView("resultados")}
                  >Resultados</button>
                </div>
                <div className="row">
                  {changeView == "evaluaciones" ?
                  <Evaluaciones/>
                  :
                  <Resultados/>
                  }
                </div>
            </section>
        </main>
    <Footer />
    </>
  )
}
