import React, { useState, useEffect } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import useInfo from "../../hooks/useInfo";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const AjustesTitulo = () => {
  const { menu } = useMenuToggle();
  const { titulo, obtenerTitulo } = useInfo();
  const [error, setError] = useState("");
  const [newTitulo, setNewTitulo] = useState("");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(newTitulo === ""){
      Swal.fire("ADVERTENCIA!", "EL campo titulo no puede estar vacio", "warning");
      return;
    }

    const URL_API = `${url_api}/api/info/agregar-titulo` || "http://localhost:3001/api/info/agregar-titulo";
    try {
      const { data } = await axios.post(URL_API, {titulo: newTitulo});
      obtenerTitulo();
      Swal.fire("cambios guardados!", data.message, "success");
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al agregar el correo", "warning");
    }
  }

  return (
    <>
        <section className="section">
            <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Titulo</th>
                          <th scope="col" colSpan={2}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        {titulo ? 
                        
                          <>
                            <td>{titulo.titulo ? titulo.titulo  : ""}</td>
                            <td>
                              <button
                                className="btn btn-warning btn-sm"
                                data-bs-toggle="modal" 
                                data-bs-target="#modalTitulo"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                            </td>
                          </>  
                        
                        : 

                          <>
                            <td>No hay información</td>
                            <td></td>
                            
                          </> 
                        
                        }
                            
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="modalTitulo" tabIndex="-1" aria-labelledby="exampleModalCorreo" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCorreo">Modificar Titulo</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <label>Indica el Nuevo Titulo</label>
                  <input type='text' className='form-control'
                  onChange={(e) => setNewTitulo(e.target.value)}
                  />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                  <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Guardar</button>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
