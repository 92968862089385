import React, { useState, useEffect } from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import { Correos } from "../correos/Correos";
import { AjustesLogo } from "./AjustesLogo";
import { AjustesTitulo } from "./AjustesTitulo";
import { AjustesInformacion } from "./AjustesInformacion";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const Ajustes = () => {
  const { menu } = useMenuToggle();

  return (
    <>
    <Header />
    <Sidebar />
    <main
        id="main"
        className="main"
        style={{ marginLeft: menu ? "" : "0px" }}
      >

        <div className="pagetitle">
          <h1>Ajustes</h1>
        </div>
        <div className="accordion" id="accordionExample">

        <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Ajustes del logo
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <AjustesLogo/>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Ajustes del Titulo
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <AjustesTitulo/>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Ajustes de información de la pagina
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <AjustesInformacion/>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                Ajustes de correo electronico
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <Correos/>
              </div>
            </div>
          </div>
        </div>
    </main>
    <Footer />
    </>
  )
}
