import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { userSelector, login, clearInfo } from "../../slices/user/userSlice";
import { showAlertLogin, showAlertNormal } from "../../utils/showAlert";
import { validateLogin } from "../../utils/validation";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

export const RecoveryPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const handleSubmit = async(e) => {
    e.preventDefault();

    if(password === "" || passwordRepeat === ""){
      Swal.fire("ADVERTENCIA!", "Todos los campos deben estar llenos", "warning");
      return;
    }

    if(password !== passwordRepeat){
      Swal.fire("ADVERTENCIA!", "las contraseñas no coinciden", "warning");
      return;
    }
    
    const URL_API = `${url_api}/api/user/update-password/${params.id}` || `http://localhost:3001/api/user/update-password/${params.id}`;
    try {
      const { data } = await axios.post(URL_API, { newPassword: password });
      Swal.fire("cambios guardados!", data.message, "success");
      navigate("/")
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error", "warning");
    }
    
  }

  return (
    <main id="login">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div className="logo d-flex align-items-center w-auto">
                    <img src={logo} alt="" />
                    <span className="d-none d-lg-block">MSLAPS</span>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Recupera tu contraseña
                      </h5>
                      <p className="text-center small">
                        Ingrese su nueva contraseña
                      </p>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Contraseña
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="yourPassword"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Repita la Contraseña
                        </label>
                        <input
                          type="password"
                          name="passwordConfirm"
                          className="form-control"
                          id="yourPasswordConfirm"
                          onChange={(e) => setPasswordRepeat(e.target.value)}
                          required
                        />
                      </div>
                      <button className="btn btn-primary d-block">Guardar Cambios</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
