import React, { useEffect, useState, useRef } from "react";
import { Certificates } from "../certificates/Certificates";
import { userSelector } from "../../slices/user/userSlice";
import { useSelector } from "react-redux";
import { validateDocuments } from "../../utils/validation";

export const Form = ({
  handleChange,
  handleSubmit,
  input,
  action,
  Projects,
  Cargos,
  errors,
  cantCertificates,
  setInput,
  setProyectosMultiples,
  proyectosMultiples,
}) => {

  const dataCertificate = {
    //name: "",
    //expiration: "",
    //nameArchivo: "",
  };

  const count = 1;

  const { user } = useSelector(userSelector);

  const [inputsCertificates, setInputsCertificates] = useState(
    Array(count).fill(dataCertificate)
  );
  const [cargosOperarios, setCargosOperarios] = useState([]);

  const [errorsDocuments, setErrorsDocuments] = useState([]);

  const projectsUser = user.projects.map(el => {
    return el.name
  })

  const inputDni = useRef();
  const inputName = useRef();
  const inputLastName = useRef();
  const inputPhone = useRef();
  const inputContactEmergency = useRef();
  const inputPhoneEmergency = useRef();
  const inputEmail = useRef();
  const inputTypeBlood = useRef();
  const inputSalary = useRef();
  const inputPassword = useRef();

  useEffect(() => {
    Cargos.forEach(el => {
      if(el.name.includes("Operario")){
        setCargosOperarios(cargosOperarios => [...cargosOperarios, el]);
      }
    })
  }, []);
  
  useEffect(() => {
    if(user.role.value !== "operario" ){
      inputDni.current.value = input.dni;
      inputName.current.value = input.name;
      inputLastName.current.value = input.lastName;
      inputPhone.current.value = input.phone;
      inputContactEmergency.current.value = input.contactEmergency;
      inputPhoneEmergency.current.value = input.phoneEmergency;
      inputEmail.current.value = input.email;
      inputTypeBlood.current.value = input.typeBlood;
      inputSalary.current.value = input.salary;
      inputPassword.current.value = input.password;
    }
  }, [input])

  useEffect(() =>{
    if(cantCertificates > 0 && action === "create"){
      const error = validateDocuments(inputsCertificates);
      if(error.name || error.nameArchivo || error.expiration){
        setErrorsDocuments([error]);
      }else{
        setErrorsDocuments([]);
      }
    }
  }, [inputsCertificates])

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      id="verticalycentered"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {action && action === "create"
                ? "Agregar " + input.roleId
                : "Editar " + input.roleId}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="row g-3" onSubmit={(e) => handleSubmit(e)}>
              {user.role.value !== "operario" ? (
                <>
                  <div className="col-6">
                    <label htmlFor="dni" className="form-label">
                      DNI/Cédula<span className='text-danger'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onBlur={(e) => handleChange(e)}
                      //onChange={(e) => handleChange(e)}
                      //value={input.dni}
                      ref={inputDni}
                      name="dni"
                      id="dni"
                    />
                    {errors.dni && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.dni}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="name" className="form-label">
                      Nombre(s)<span className='text-danger'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onBlur={(e) => handleChange(e)}
                      ref={inputName}
                      name="name"
                      id="name"
                    />
                    {errors.name && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="lastName" className="form-label">
                      Apellido(s)<span className='text-danger'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onBlur={(e) => handleChange(e)}
                      ref={inputLastName}
                      name="lastName"
                      id="lastName"
                    />
                    {errors.lastName && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.lastName}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="birthday" className="form-label">
                      Fecha de Nacimiento<span className='text-danger'>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={input.birthday}
                      name="birthday"
                      id="birthday"
                    />
                    {errors.birthday && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.birthday}</span>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-6">
                <label htmlFor="phone" className="form-label">
                  Teléfono<span className='text-danger'>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onBlur={(e) => handleChange(e)}
                  ref={inputPhone}
                  name="phone"
                  id="phone"
                />
                {errors.phone && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.phone}</span>
                  </div>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="contactEmergency" className="form-label">
                  Nombre del Contacto de Emergencia<span className='text-danger'>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onBlur={(e) => handleChange(e)}
                  ref={inputContactEmergency}
                  name="contactEmergency"
                  id="contactEmergency"
                />
                {errors.contactEmergency && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.contactEmergency}</span>
                  </div>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="phoneEmergency" className="form-label">
                  Telf. de Emergencia<span className='text-danger'>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onBlur={(e) => handleChange(e)}
                  ref={inputPhoneEmergency}
                  name="phoneEmergency"
                  id="phoneEmergency"
                />
                {errors.phoneEmergency && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.phoneEmergency}</span>
                  </div>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="email" className="form-label">
                  Email<span className='text-danger'>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  onBlur={(e) => handleChange(e)}
                  ref={inputEmail}
                  name="email"
                  id="email"
                />
                {errors.email && (
                  <div
                    className="alert alert-danger alert-dismissible fade show my-2"
                    role="alert"
                  >
                    <span>{errors.email}</span>
                  </div>
                )}
              </div>
              {user.role.value !== "operario" ? (
                <>
                  <div className="col-6">
                    <label htmlFor="typeBlood" className="form-label">
                      Tipo de Sangre<span className='text-danger'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onBlur={(e) => handleChange(e)}
                      ref={inputTypeBlood}
                      name="typeBlood"
                      id="typeBlood"
                    />
                    {errors.typeBlood && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.typeBlood}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="salary" className="form-label">
                      Salario
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onBlur={(e) => handleChange(e)}
                      ref={inputSalary}
                      name="salary"
                      id="salary"
                      min={0}
                      step={1}
                    />
                  </div>

                  <div className="col-6">
                    <label htmlFor="password" className="form-label">
                      Contraseña<span className='text-danger'>*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onBlur={(e) => handleChange(e)}
                      ref={inputPassword}
                      name="password"
                      id="password"
                    />
                    {errors.password && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.password}</span>
                      </div>
                    )}
                  </div>

                  <div className="col-6">
                    <label className="form-label">Cargo<span className='text-danger'>*</span></label>
                    <select
                      name="positionId"
                      className="form-control"
                      value={input.positionId}
                      onChange={(e) => handleChange(e)}
                    >
                      <option>Selecciona un Cargo</option>
                      {input.roleId.includes("operario") ? 
                      <>
                      {cargosOperarios &&
                        cargosOperarios.map((el, index) => (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                        ))} 
                      </>
                      : 
                      
                      <>
                      {Cargos &&
                        Cargos.map((el, index) => (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                        ))} 
                      </>
                      
                      }
                    </select>
                    {errors.positionId && (
                      <div
                        className="alert alert-danger alert-dismissible fade show my-2"
                        role="alert"
                      >
                        <span>{errors.positionId}</span>
                      </div>
                    )}
                  </div>
                  {action && action === "create" ? (
                    <div className="col-6">
                      <label className="form-label">Proyecto<span className='text-danger'>*</span></label>
                      {input.roleId === "operario" ? 
                      
                      <select
                        name="projectId"
                        className="form-control"
                        onChange={(e) => {
                          if(!proyectosMultiples.includes(e.target.value)){
                            if(e.target.value == "") return;
                            setProyectosMultiples([e.target.value])
                          }
                        }}
                      >
                        <option value="" selected>Selecciona un Proyecto</option>
                        {Projects &&
                          Projects.map((el, index) => (
                            projectsUser.length < 1 ?
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                              :
                              projectsUser.includes(el.name) ? <option key={index} value={el.id}>
                                {el.name}
                              </option>
                                : ""
                          )

                          )}
                      </select> 
                      
                      : 
                      
                      <select
                        multiple
                        name="projectId"
                        className="form-control"
                        onChange={(e) => {
                          if(!proyectosMultiples.includes(e.target.value)){
                            if(e.target.value == "") return;
                            setProyectosMultiples([...proyectosMultiples, e.target.value])
                          }else{
                            const resultado = proyectosMultiples.filter(proyecto => proyecto != e.target.value);
                            setProyectosMultiples(resultado);
                          }
                        }}
                      >
                        <option value="">Selecciona un Proyecto</option>
                        {Projects &&
                          Projects.map((el, index) => (
                            projectsUser.length < 1 ?
                              <option key={index} value={el.id}>
                                {el.name} {proyectosMultiples.includes(el.id) ? <a>&#10003;</a> : ""}
                              </option>
                              :
                              projectsUser.includes(el.name) ? <option key={index} value={el.id}>
                                {el.name}
                              </option>
                                : ""
                          )

                          )}
                      </select> 
                      
                      }
                      
                      {errors.projectId && (
                        <div
                          className="alert alert-danger alert-dismissible fade show my-2"
                          role="alert"
                        >
                          <span>{errors.projectId}</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {(user.role.value === "admin" ||
                    user.role.value === "contratista") &&
                    cantCertificates > 0 && action !== "edit" ? (
                    <div className="col-12">
                      <Certificates
                        count={cantCertificates}
                        input={input}
                        errors={errors}
                        setInput={setInput}
                        inputsCertificates={inputsCertificates}
                        setInputsCertificates={setInputsCertificates}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  disabled={
                    Object.keys(errors).length > 0 || input.dni === "" || 
                    input.password === "" || input.password === undefined
                      ? true
                      : errorsDocuments.length > 0
                      ? true : false
                  }
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
