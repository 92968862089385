import React, { useEffect, useState } from "react";
import useMenuToggle from "../../hooks/useMenuToggle";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import { userSelector } from "../../slices/user/userSlice";
import { getUserById } from "../../slices/userCrudSlice/userCrudSlice";
import { updateCertificate,validateCertificate } from "../../slices/certificate/certificateSlice";
import { Link, useParams } from "react-router-dom";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

export const CertificatesUser = () => {
  const { menu } = useMenuToggle();
  const { user } = useSelector(userSelector);
  const { userById } = useSelector((state) => state.userCrud);
 
  const [input, setInput] = useState({ observation: "", motivo: "" });
  const [observation, setObservation] = useState({});
  const [certificateValidate,setCertificateValidate]=useState(false);
  const [pdfSelected, setPdfSelected] = useState({});
  const [newPdf, setNewPdf] = useState({});
  const [newNamePdf, setNewNamePdf] = useState("");
  const [newExpirationPdf, setNewExpirationPdf] = useState("");

  const { idUser } = useParams();
  const dispatch = useDispatch();

  function difference(date1, date2) {
    const date1utc = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const date2utc = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );
    let day = 1000 * 60 * 60 * 24;
    return (date2utc - date1utc) / day;
  }

  const dateCurrent = new Date();

  const arrayCertificates = userById?.certificates?.map((el) => {
    const dateExpiration = new Date(el.expiration);
    dateExpiration.setMinutes(
      dateExpiration.getMinutes() + dateExpiration.getTimezoneOffset()
    );
    return {
      ...el,
      daysExpiration: difference(dateCurrent, dateExpiration),
    };
  });

  const limpiar = () => {
    setInput({
      id: "",
      observation: "",
      motivo: ""
    });
  };

  const insertIdObservacion = (id) => {
    setInput({
      ...input,
      id,
    });
  };

  const handleObservacion = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const submitObservacion = (e) => {
    e.preventDefault();
    if(input.observation == "" || input.motivo == ""){
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "debes llenar todos los campos!",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    dispatch(updateCertificate(input.id, input));

    setTimeout(() => {
      dispatch(getUserById(idUser));
    }, 2000);

    limpiar();
  };

  const validate=async(id,value)=>{

   dispatch(validateCertificate(id,value))
   setCertificateValidate(true)   
  }

  const handleClick = (pdf) => {
    setPdfSelected(pdf);
  }


  const guardarPdf = async (e) => {
    e.preventDefault();

    if(newPdf == "" || newExpirationPdf == ""){
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "debes llenar todos los campos!",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    let nameArchivo = newNamePdf.split(".").shift();
    let ext = newPdf.name.split(".").pop();
    let newName = nameArchivo + "." + ext;

    let formData = new FormData();
    let timeStamp = Date.now();
    formData.append("certificates", JSON.stringify({ name: newPdf, expiration: newExpirationPdf, nameArchivo: newName  }));
    formData.append("files", newPdf);

    try {
      const { data } = await axios.put(url_api + "/api/admin/certificates/" + pdfSelected.id, formData);
      dispatch(getUserById(idUser));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "hubo un error al editar!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  
  } 

  useEffect(()=>{
   dispatch(getUserById(idUser))
   setCertificateValidate(false)
  },[dispatch,certificateValidate])

  useEffect(() => {
    setNewNamePdf(pdfSelected.name)
  }, [pdfSelected])

  return (
    <>
      <Header />
      <Sidebar />
      <main
        id="main"
        className="main"
        style={{ marginLeft: menu ? "" : "0px" }}
      >
        <section className="section dashboard">
          <div className="pagetitle">
            <h1>
              {user.role.value === "operario"
                ? "Observaciones"
                : "Certificados de " + userById.name + " " + userById.lastName}
            </h1>
          </div>
          <section className="section">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Fecha Expiración</th>
                            <th scope="col">Estatus</th>
                            <th scope="col">Observación</th>
                            <th scope="col">Motivo</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Editar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {arrayCertificates?.map((el, index) =>
                            user.role.value === "operario" &&
                            el.observation === null ? (
                              ""
                            ) : (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={url_api+"/" + userById.dni +"/"+ el.name}
                                    target="_blank"
                                  >
                                    {el.name}
                                  </Link>
                                </td>
                                <td>{el.expiration}</td>
                                <td>
                                  {!el.isValid ? 
                                  <>
                                  <span className="btn btn-outline-success disabled btn-sm">Por validar</span>
                                  {user.role.value === "admin" || user.role.value === "supervisor" ? 
                                  <button  type="button" onClick={()=>validate(el.id,true)} className="btn btn btn-outline-success  ms-2 btn-sm"><i className="bi bi-check-lg"></i></button>
                                  :""}
                                  </>
                                 :
                                  <span
                                    className={
                                      "btn " +
                                      (el.daysExpiration <= 0
                                        ? "btn-danger"
                                        : el.daysExpiration <= 10
                                        ? "btn-warning"
                                        : "btn-success") +
                                      " btn-sm"
                                    }
                                    style={{ width: 100 }}
                                  >
                                    {el.daysExpiration <= 0
                                      ? "Caducado"
                                      : el.daysExpiration <= 10
                                      ? "Por Caducar"
                                      : el.status}
                                  </span>
                                  }
                                </td>
                                <td>
                                  {user.role.value === "supervisor" ? (
                                    <button
                                      className="btn btn-warning btn-sm me-2"
                                      data-bs-toggle="modal"
                                      data-bs-target="#agregarObservation"
                                      onClick={() => insertIdObservacion(el.id)}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {el.observation !== null ? (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      data-bs-toggle="modal"
                                      data-bs-target="#verObservacion"
                                      onClick={() =>
                                        setObservation({ observation: el.observation, motivo: el.motivo, fecha: el.fechaobservacion })
                                      }
                                    >
                                      ver
                                    </button>
                                  ) : (
                                    "No hay observación"
                                  )}
                                </td>
                                <td>{el.motivo ? el.motivo : "sin información"}</td>
                                <td>{el.fechaobservacion ? el.fechaobservacion : "sin información"}</td>
                                <td><button
                                      className="btn btn-warning btn-sm me-2"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editarPdf"
                                      onClick={() => handleClick(el)}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            className="modal fade"
            id="agregarObservation"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    Agregar Observación
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form onSubmit={(e) => submitObservacion(e)}>
                  <div className="modal-body">
                    <label className="col-form-label">
                      Escriba el motivo
                    </label>
                    <input type="text" className="form-control" name="motivo" value={input.motivo} onChange={(e) => handleObservacion(e)}/>
                    <label className="col-form-label">
                      Escriba la Observación
                    </label>
                    <textarea
                      name="observation"
                      value={input.observation}
                      onChange={(e) => handleObservacion(e)}
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => limpiar()}
                    >
                      Cerrar
                    </button>
                    <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="verObservacion"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Observación
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Motivo: {observation.motivo ? observation.motivo : "sin información"}</p>
                  <p>Descripción: {observation.observation}</p>
                  <p>fecha de la Observación: {observation.fecha ? observation.fecha : "sin información"}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Modal edicion o cambio de pdf--> */}
            <div className="modal fade" id="editarPdf" tabindex="-1" aria-labelledby="editarPdfModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="editarPdfModalLabel">Editar el Archivo {pdfSelected.name}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <input type="hidden" className="form-control" id="nombrePdf" name="nombrePdf"
                    value={pdfSelected.name}
                    />
                    <label className="mt-2">Selecciona el nuevo Pdf</label>
                    <input type="file" className="form-control" id="editarPdf" name="editarPdf"
                    onChange={(e) => setNewPdf(e.target.files[0])}
                    />
                    <label className="mt-2">Fecha de expiración</label>
                    <input type="date" className="form-control" id="fechaPdf" name="fechaPdf"
                    onChange={(e) => setNewExpirationPdf(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" className="btn btn-primary" 
                    onClick={(e) => guardarPdf(e)}  data-bs-dismiss="modal"
                    >Guardar</button>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
