import { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { userSelector } from "../../slices/user/userSlice";
import dayjs from "dayjs";
import { getEvaluation, 
        getPreguntas, 
        evaluarPregunta, 
        reiniciarEvaluacion, 
        addDataStatus } from "../../slices/evaluacion/evaluacion";
import { PreguntaMultiple } from "./PreguntaMultiple";
import { PreguntaBreve } from "./PreguntaBreve";
import { PreguntaEnumeracion } from "./PreguntaEnumeracion";
import { PreguntaCompletacion } from "./PreguntaCompletacion";
import { PreguntaAlgebra } from "./PreguntaAlgebra";
import { Finish } from "./Finish";
import Swal from "sweetalert2";

export const Evaluacion = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacionStatus = useSelector((state) => state.evaluacion.evaluacionStatus);
  const preguntas = useSelector((state) => state.evaluacion.preguntas);
  const { user } = useSelector(userSelector);

  const [allPreguntas, setAllPreguntas] = useState([]);
  const [fechaFinal, setFechaFinal] = useState("");
  const [steps, setSteps] = useState(0);
  const [actualStep, setActualStep] = useState(0);
  const [timeFinish, setTimeFinish] = useState(false);
  const [dataForm, setDataForm] = useState([]);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClick = async() => {
    dispatch(evaluarPregunta({ ...dataForm, userId: user.id}));
    setDataForm([]);
    setReset(true);
    setActualStep(actualStep+1);
    localStorage.setItem("paso_evaluacion", JSON.stringify({ evaluacion: params.id, paso: actualStep+1 }));
  }

  const handleReiniciar = async() => {
    Swal.fire({
      title: 'Estas seguro que quieres reiniciar esta prueba?',
      showDenyButton: true,
      confirmButtonText: 'Iniciar',
      denyButtonText: `No Iniciar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // let fecha = dayjs();
        // let fechaFinalizacion = fecha.add(evaluacion.time, 'minute');
        let evaluacionData = { ...evaluacion }
        dispatch(reiniciarEvaluacion(evaluacionData, user ));
        setActualStep(0);
        let intento = evaluacionStatus.intento + 1;
        dispatch(addDataStatus({ ...evaluacionStatus, intento }))
        localStorage.setItem("paso_evaluacion", JSON.stringify({ evaluacion: params.id, paso: 0 }));
        window.location.reload()
      } else if (result.isDenied) {
        Swal.fire('Has decidido no iniciar la evaluación', '', 'info');
      }
    })
  }

  const handleExit = () => {
    Swal.fire({
      title: 'Estas seguro que deseas salir de la prueba?',
      showDenyButton: true,
      confirmButtonText: 'Salir',
      denyButtonText: `Permanecer`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate("/dashboard/evaluaciones-user");
      }
    })
  }

  const preguntasSeccion = Array.from({ length: steps }, (_, index) => (
    <>
      {allPreguntas[index].tipo == "multiple" ?
        <PreguntaMultiple content={allPreguntas[index]} setDataForm={setDataForm} reset={reset} setReset={setReset}/>
        :
        ""
      }
      {allPreguntas[index].tipo == "enumeracion" ?
        <PreguntaEnumeracion content={allPreguntas[index]} setDataForm={setDataForm} reset={reset} setReset={setReset}/>
        :
        ""
      }
      {allPreguntas[index].tipo == "breve" ?
        <PreguntaBreve content={allPreguntas[index]} setDataForm={setDataForm} reset={reset} setReset={setReset}/>
        :
        ""
      }
      {allPreguntas[index].tipo == "completacion" ?
        <PreguntaCompletacion content={allPreguntas[index]} setDataForm={setDataForm} reset={reset} setReset={setReset}/>
        :
        ""
      }
      {allPreguntas[index].tipo == "algebra" ?
        <PreguntaAlgebra content={allPreguntas[index]} setDataForm={setDataForm} reset={reset} setReset={setReset}/>
        :
        ""
      }
    </>
  ));

  const Completionist = () => <span>El Tiempo Ha Finalizado!</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setTimeFinish(true)
      return <Completionist />;
    } else {
      return <span className="fs-1">{hours}:{minutes}:{seconds} <i class='bx bxs-timer'></i></span>;
    }
  };

  useEffect(() => {
    const { id } = params;
    dispatch(getPreguntas(id));
    dispatch(getEvaluation(id, user.id));
  }, [])

  useEffect(() => {
    let fecha = dayjs(evaluacionStatus.fechaLimit).format("YYYY-MM-DD H:mm:ss");
    setFechaFinal(fecha);
  }, [evaluacionStatus])

  useEffect(() => {
    let array = [];
    let arrayTotal = array.concat(
      preguntas.multiple, 
      preguntas.enumeracion,
      preguntas.breve,
      preguntas.completacion,
      preguntas.algebra);
    setSteps(preguntas.cantidadPreguntas);
    setAllPreguntas(arrayTotal)
  }, [preguntas])

  useEffect(() => {
    setLoading(false)
  }, [])
  
  useEffect(() => {
    const pasoEvaluacion = localStorage.getItem("paso_evaluacion");
    if(JSON.parse(pasoEvaluacion)?.evaluacion == params.id){
      setActualStep(JSON.parse(pasoEvaluacion)?.paso);
      setFechaFinal(JSON.parse(pasoEvaluacion)?.fecha)
      setTimeout(() => {
        setLoading(false);
      }, 2000)
    }else{
      localStorage.setItem("paso_evaluacion", JSON.stringify({ evaluacion: params.id, paso: 0, fecha: dayjs().format("YYYY-MM-DD H:mm:ss") }));
      setTimeout(() => {
        setLoading(false);
      }, 2000)
    }
  }, [])

  if(!evaluacionStatus && !fechaFinal) return null;

  if(loading) return null;

  return (
    <>
      <div className="d-flex flex-column col-12 justify-content-center align-items-center p-4">
        <div className="d-flex flex-row justify-content-between align-items-center col-10">
          <div class="tooltip-container">
            <button className="btn"
            onClick={handleExit}
            ><i class='bx bxs-exit bx-md'></i></button>
            <span class="tooltip-text">Salir de la Evaluación</span>
          </div>
          <div class="tooltip-container">
            <p>Intentos Restantes {evaluacion?.numInt - evaluacionStatus.intento}</p>
            <button className="btn" onClick={handleReiniciar}
            ><i class='bx bx-reset bx-md'></i></button>
            <span class="tooltip-text">Reiniciar Evaluación</span>
          </div>
        </div>
        <h2>Hola {user?.name + " " + user?.lastName}</h2>
        <p>Bienvenido a la Evaluación {evaluacion.nombre}</p>
        <p>tienes un tiempo de {evaluacion.time} minutos para finalizar</p>
        <p>y un maximo de {evaluacion?.numInt} Intentos</p>
        {fechaFinal && !loading ?
        <Countdown
            date={fechaFinal}
            renderer={renderer}
          />
          :
          ""
        }

        {timeFinish ?

          <Finish evaluationId={params.id} userId={user.id} setFechaFinal={setFechaFinal}/>
          
          :
          <>
          {actualStep < steps ?
        
          preguntasSeccion[actualStep]
  
          :
  
          <Finish evaluationId={params.id} userId={user.id} setFechaFinal={setFechaFinal}/>
          }
          </>
        }

        <div className="d-flex col-10 col-md-4 justify-content-end align-items-center">
          <button className={`btn ${actualStep >= steps || timeFinish ? "disabled d-none" : ""}`}
            onClick={handleClick}
          >
            <i class='bx bx-right-arrow-alt bx-sm' ></i>
          </button>
        </div>
        
      </div>
    </>
  )
}
