import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import useMenuToggle from "../../hooks/useMenuToggle";
import axios from "axios";
import { url_api } from "../../utils/config";
import {
  getAllProjects,
} from "../../slices/project/projectSlice";
import Swal from "sweetalert2";

export const Historial = () => {
  const params = useParams();
  const { menu } = useMenuToggle();
  const [historial, setHistorial] = useState([]);
  const [usuario, setUsuario] = useState("");
  const [errors, setErrors] = useState({});

  const obtenerHistorial= async()=> {
      const URL_API = `${url_api}/api/admin/history/${params.id}` || `http://localhost:3001/api/admin/history/${params.id}`;
      try {
        const { data } = await axios.get(URL_API);

        setUsuario(data.name + " " + data.lastName);
        setHistorial(data.proyectos);
      } catch (error) {
        setErrors({ error: "hubo un error"});
      }
  }

  useEffect(() => {
    obtenerHistorial();
  }, [])


  return (
    <>
    <Header />
    <Sidebar />
    <main
        id="main"
        className="main"
        style={{ marginLeft: menu ? "" : "0px" }}
      >

        <div className="pagetitle">
          <h1>Historial del Usuario {usuario}</h1>
        </div>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="content">
                                  <ul className="timeline">
                                {historial ?
                                
                                    <>
                                      {historial.map((data, index) =>(
                                        <li className="event" data-date={data.dataValues.fechainicio.split("T")[0] + " - " + (data.dataValues.fechafin ? data.dataValues.fechafin.split("T")[0] : "Fecha Actual" )}>
                                          <h3>Nombre del Proyecto <strong>{data.dataValues.name}</strong></h3>
                                          <p>Cantidad de certificados <strong>{data.dataValues.totalCertificates}</strong></p>
                                          <p> Ubicación 
                                            <a
                                              href={data.dataValues.location}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i className="bi bi-geo-alt-fill"></i>
                                            </a>
                                          </p>
                                        </li>
                                      ))}
                                    </>
                                    
                                  : 

                                    <li className="event" data-date="12:30 - 1:00pm">
                                        <h3>No hay información</h3>
                                    </li>
                                  }
                                  </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
            
        </section>
          
    </main>
    <Footer />
    </>
  )
}
