import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resultadoEvaluacion } from "../../slices/evaluacion/evaluacion";

export const Finish = ({ evaluationId, userId, setFechaFinal }) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resultado = useSelector((state) => state.evaluacion.resultado);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(!resultado.status){
      dispatch(resultadoEvaluacion(evaluationId, userId))
    }

  }, [])

  useEffect(() => {
    if(resultado){
      setLoading(false);
      setFechaFinal(resultado?.fecha);
    }
  }, [resultado])

  return (
    <div className='card col-10 col-md-6 bg-secondary mt-2 p-4'>
        <h3 className='text-white text-center'>Haz Finalizado la Prueba</h3>
        {loading ?
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div class="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p className="text-white">Espere un momento...</p>
          <p className="text-white">Obteniendo resultados...</p>
        </div>
        :
        <>
          <div className='row'>
              <div className='col-12 col-md-6 text-white'>
                  <p className='text-center'>Respuestas Correctas {resultado?.correctas}</p>
              </div>
              <div className='col-12 col-md-6 text-white'>
                  <p className='text-center'>Respuestas Incorrectas {resultado?.incorrectas}</p>
              </div>
              <div className='col-12 text-white'>
                  <p className='text-center'>Tu resultado ha sido {resultado?.puntuacion}</p>
                  <p className={`${resultado?.status == "aprobado" ? "text-success" : "text-danger"} text-center
                  fw-bold`}>Has {resultado?.status}</p>
                  <p className="text-center">Preguntas Respondidas {resultado?.preguntasRespondidas} de {resultado?.preguntasTotales}</p>
              </div>
          </div>
          <button className="btn btn-dark" onClick={() => navigate("/dashboard/evaluaciones-user")}> ir al inicio</button>
        </>
        }
    </div>
  )
}
