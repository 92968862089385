import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

const URL_API = url_api+"/api" || "http://localhost:3001/api";

const initialState = {
    asistencias: [],
    success: "",
    errores: "",
    documento: ""
}

export const asistenciaSlice = createSlice({
    name: "asistencia",
    initialState,
    reducers: {
        asistencias: (state, action) => {
            state.asistencias = action.payload.dataAsistencias
        },
        success: (state, action) => {
            state.success = action.payload.dataSuccess
        },
        errores: (state, action) => {
            state.errores = action.payload.dataErrores
        },
        clearData: (state, action) => {
            state.errores = ""
            state.success = ""
        },
        documento: (state, action) => {
            state.documento = action.payload.dataDocumento
        },
    },
})

const { asistencias, errores, success, clearData, documento } = asistenciaSlice.actions;

export const getAsistencias = () => async (dispatch) => {
    const response = await axios.get(URL_API + `/asistencias/all`);
    dispatch(asistencias({dataAsistencias: response.data}))
}

export const getAsistenciasUser = (id) => async (dispatch) => {
        const response = await axios.get(URL_API + `/asistencias/user/${id}`);
        dispatch(asistencias({dataAsistencias: response.data}))
}

export const addAsistenciaUser = (id, data) => async (dispatch) => {
    try {
        const response = await axios.post(URL_API + `/asistencias/user/${id}`, data);
        dispatch(success({dataSuccess: response.data.message}))
    } catch (error) {
        dispatch(errores({dataErrores: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data?.errors[0].msg }));
    }
}

export const generarData = (dataAsistencias) => async (dispatch) => {
    try {
        const response = await axios.post(URL_API + `/asistencias/generate-data`, dataAsistencias);
        dispatch(success({dataSuccess: response.data.message}))
        dispatch(documento({dataDocumento: response.data.documento}))
    } catch (error) {
        dispatch(errores({dataErrores: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data?.errors[0].msg }));
    }
}

export const limpiarInfo = () => (dispatch) => {
    dispatch(clearData())
}

export const asistenciaSelector = (state) => state.asistencia;