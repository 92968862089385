import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import useMenuToggle from "../../hooks/useMenuToggle";
import { getEvaluaciones, iniciarEvaluacion, limpiarEvaluacion, getStatusEvaluation } from "../../slices/evaluacion/evaluacion";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import Swal from "sweetalert2";
import { url_api } from "../../utils/config";
import "../../index.css";
import localStorage from "redux-persist/es/storage";

export const EvaluacionesUser = () => {

  const navigate = useNavigate();
  const { menu } = useMenuToggle();
  const { user } = useSelector(userSelector);
  const evaluaciones = useSelector((state) => state.evaluacion.evaluaciones);
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacionStatus = useSelector((state) => state.evaluacion.evaluacionStatus);
  const videoRef = useRef(null);

  const [evaluacionSelected, setEvaluacionSelected] = useState({});
  const [video, setVideo] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [redirigir, setRedirigir] = useState(false);

  const dispatch = useDispatch();

  const handleClick = async(item) => {
    Swal.fire({
      title: 'Estas seguro que quieres iniciar esta prueba? recuerda revisar la información correspondiente a la prueba antes de iniciar',
      showDenyButton: true,
      confirmButtonText: 'Iniciar',
      denyButtonText: `No Iniciar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //let fecha = dayjs();
        //let fechaFinalizacion = fecha.add(item.time, 'minute');
        dispatch(iniciarEvaluacion(item, user));
        localStorage.setItem("paso_evaluacion", JSON.stringify({ evaluacion: item.id, paso: 0 }));
        setRedirigir(true);
      } else if (result.isDenied) {
        Swal.fire('Has decidido no iniciar la evaluación', '', 'info');
      }
    })
  }

  const handleStatus = async(id) => {
    dispatch(getStatusEvaluation(id, user.id))
  }

  useEffect(() => {
    dispatch(getEvaluaciones(user.id));
    dispatch(limpiarEvaluacion());
  }, [])

  useEffect(() => {
    if(video){
      videoRef.current.src = `${url_api}/evaluations/${video}`;
    }
  }, [video])

  useEffect(() => {
    if(redirigir){
      if(!evaluacion?.id) return;
      navigate(`/dashboard/evaluaciones-user/${evaluacion?.id}`);
    }
  }, [redirigir, evaluacion])

  return (
    <>
      <Header />
      <Sidebar />
      <main
            id="main"
            className="main"
            style={{ marginLeft: menu ? "" : "0px" }}
        >
            <section className="section">
                <div className="pagetitle">
                    <h1>Evaluaciones</h1>
                </div>

                <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body" style={{height: "350px", overflowY: "scroll", width: "100%"}}>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Nombre evaluación</th>
                              <th scope="col">Puntuación</th>
                              <th scope="col">Cal. minima</th>
                              <th scope="col">Intentos</th>
                              <th scope="col">Cant. preguntas</th>
                              <th scope="col">Información</th>
                            </tr>
                          </thead>
                          <tbody>
                            {evaluaciones && evaluaciones.map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{item.nombre}</td>
                                  <td>{item.puntuacion}</td>
                                  <td>{item.calMin}</td>
                                  <td>{item.numInt}</td>
                                  <td>{item.cantQuest}</td>
                                  <td>
                                    <>

                                    <div class="tooltip-container">
                                      <button className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalStatus"
                                      onClick={() => {
                                        setEvaluacionSelected(item);
                                        handleStatus(item.id)
                                      }}
                                      ><i class='bx bx-search-alt-2'></i></button>
                                      <span class="tooltip-text">Status Evaluación</span>
                                    </div>

                                    <div class="tooltip-container">
                                      <button className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalVideo"
                                      onClick={() => {
                                        setVideo(item?.video);
                                        setVideoUrl(item?.url);
                                      }}
                                      ><i class='bx bxs-videos'></i></button>
                                      <span class="tooltip-text">Reproducir Video</span>
                                    </div>

                                    <div class="tooltip-container">
                                      <button className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalDescripcion"
                                      onClick={() => setDescripcion(item?.descripcion)}
                                      >
                                        <i class='bx bx-book-open'></i>
                                      </button>
                                      <span class="tooltip-text">Descripción de la evaluación</span>
                                    </div>

                                    <div class="tooltip-container">
                                      <button className="btn"
                                      data-bs-toggle="tooltip" 
                                      data-bs-placement="top" 
                                      data-bs-title="Iniciar Prueba"
                                      onClick={() => handleClick(item)}
                                      ><i class='bx bx-play'></i></button>
                                      <span class="tooltip-text">Iniciar evaluación</span>
                                    </div>
                                    </>
                                    
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
            {/* <!-- Modal --> */}
            <Modal id={"modalVideo"} title={"Video"} size={"modal-xl"}>
              <>
              {video ? 
                <video
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  controls
                >
                </video>
                :
                ""
              }
              {videoUrl ?
              <a href={videoUrl} target="blank" rel="noopener noreferrer" >Ir al sitio externo del video</a>
              :
              ""
              }
              </>
            </Modal>
            <Modal id={"modalDescripcion"} title={"Descripción"} size={"modal-md"}>
              <p>{descripcion}</p>
            </Modal>

            <Modal id={"modalStatus"} title={"Estado de la Evaluación"} size={"modal-md"}>
              {!evaluacionStatus ?
                <>
                  <p>Aún no has realizado esta prueba</p>
                  <p>tienes <span className="fw-bold">{evaluacionSelected?.numInt}</span> Intentos disponibles</p>
                </>
                :
                <>
                  <p>Intentos restantes <span className="fw-bold">{parseInt(evaluacionSelected?.numInt) - parseInt(evaluacionStatus?.intento)}</span></p>
                  <p>Ultimo resultado <span className="fw-bold">{evaluacionStatus?.result}</span></p>
                  <p>estado <span className="fw-bold">{evaluacionStatus?.status}</span></p>
                  {evaluacionStatus?.status == "finalizada" ?
                  <p>resultado {evaluacionStatus?.result < evaluacionSelected.calMin ? <span className="fw-bold text-danger">Desaprobaste esta evaluación</span> : <span className="fw-bold text-success">Aprobado con el minimo requerido</span>}</p>
                  :
                  ""
                  }
                  
                </>
              }
            </Modal>
        </main>
      <Footer />
    </>
  )
}


const Modal = ({ id, title, children, size }) => {
  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalEvaluacion" aria-hidden="true">
      <div className={`modal-dialog ${size}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalEvaluacion">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  )
}