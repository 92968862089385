import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as faceapi from "face-api.js";
import { getBiometricImage } from '../../slices/reconocimiento/reconocimiento';
import { userSelector } from "../../slices/user/userSlice";
import { url_api } from "../../utils/config";
import { FormularioAsistencia } from "../asistencias/FormularioAsistencia";
import socketCLient from '../../utils/socketCLient';

export const Reconocimiento = ({ openModal, stopVideo, videoRef, iniciar, setIniciar }) => {

  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState([120303, 31330130]);
  const [motivo, setMotivo] = useState("");
  const [confirmUser, setConfirmUser] = useState(false);
  const [messageInfo, setMessageInfo] = useState("");
  
  const [imagenBiometrica, setImagenBiometrica] = useState(null);
  const [error, setError] = useState("");
  const [inputError, setInputError] = useState({ motivo: "Debes agregar el motivo" });

  const { user } = useSelector(userSelector);

  const imageRef = useRef();
  const canvasRef = useRef();
  const btnRef = useRef();


  useEffect(() => {
    if(iniciar){
      if(coordinates.length === 0){
        setIniciar(false);
        setInputError({ ...inputError, coordinates: "Debes activar la Geolocalización"})
        return;
      }
      startVideo();
      setError("");
      socketCLient.emit("verificacionBiometrica", { userId: user.id });
    }
  }, [iniciar])


  useEffect(() => {
    if(imagenBiometrica){
      imageRef.current.src = imagenBiometrica;
      loadModels();
    }
  }, [imagenBiometrica])

  useEffect(() => {
    socketCLient.on("verificacionBiometrica", async(data) => {
       if(!data.error){
         const blob = new Blob([data]);
         const blobUrl = URL.createObjectURL(blob);
         setImagenBiometrica(blobUrl);
       }else{
         setError(data.error);
         setIniciar(false);
         stopVideo();
       }
    })
  }, [])

  useEffect(() => {
    const obtenerCoordenadas = async() => {
      navigator.geolocation.getCurrentPosition((position, error) => {
        if(error){
          setInputError({ ...inputError, coordinates: "Debes activar la geolocalización del dispositivo" })
          return;
        }else{
          setCoordinates([position.coords.latitude, position.coords.longitude]);
          setInputError({ ...inputError, coordinates: "" });
        }
      });
    }
   obtenerCoordenadas();
  }, [])

  const handleLocation = async() => {
    navigator.geolocation.getCurrentPosition((position, error) => {
      if(error){
        setInputError({ ...inputError, coordinates: "Debes activar la geolocalización del dispositivo" })
        return;
      }else{
        setCoordinates([position.coords.latitude, position.coords.longitude]);
        setInputError({...inputError, coordinates: ""})
      }
    });
  }

  const startVideo = async() => {
    if(!videoRef.current.srcObject){
      navigator.mediaDevices.getUserMedia({ video: true })
      .then((currentStream) => {
        videoRef.current.srcObject = currentStream;
        return videoRef.current.srcObject
      })
      .catch((err) => {
        return err;
      })
    }
  }


  const loadModels = () => {
    Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
      faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models")
    ]).then(() => {
      faceMyDetect()
    }).catch(error => {
      return error;
    })
  }

  const faceMyDetect = () => {
    let count = 0;
    let interval = setInterval(async() => {
      if(confirmUser) return
      count++;
      const results = await faceapi
        .detectAllFaces(imageRef.current)
        .withFaceLandmarks()
        .withFaceDescriptors()
      
      if(results){
        results.forEach(async item => {
          const faceMatch = new faceapi.FaceMatcher(item);

          const singleResult = await faceapi
            .detectSingleFace(videoRef.current)
            .withFaceLandmarks()
            .withFaceDescriptor()

          if(singleResult){
            const bestMatch = faceMatch.findBestMatch(singleResult.descriptor)
            //canvasRef.current.innerHtml = faceapi.createCanvasFromMedia(videoRef.current)
            // faceapi.matchDimensions(canvasRef.current, {
            //   width: 620,
            //   height: 650
            // })

            // const resized = faceapi.resizeResults(singleResult, {
            //   width: 620,
            //   height: 650
            // })

            // const box = { 
            //   x: resized.alignedRect.box.x, 
            //   y: resized.alignedRect.box.y, 
            //   width: resized.alignedRect.box.width,
            //   height: resized.alignedRect.box.height
            //  }

            //  const drawOptions = {
            //   label: bestMatch.label == "unknown" && bestMatch.distance > 0.4 ? "verificando espere un momento" : count >= 40 ? "No se reconoce al usuario" : "has sido comprobado",
            //   lineWidth: 2,
            //   boxColor: (bestMatch.label == "unknown" && bestMatch.distance > 0.4) || count >= 40  ? "red" : "green"
            //  }

            //  const drawBox = new faceapi.draw.DrawBox(box, drawOptions);
            //  drawBox.draw(canvasRef.current);
             
             if(bestMatch.label !== "unknown"){
              setMessageInfo("Comprobación Éxitosa");
              setConfirmUser(true);
              clearInterval(interval);
              videoRef.current.hidden = true;
              canvasRef.current.hidden = true;
              btnRef.current.hidden = true;
              setIniciar(false);
              stopVideo();
             }else if(count >= 7){
              setMessageInfo("No fuiste comprobado por el sistema");
              setConfirmUser(false)
              clearInterval(interval);
              videoRef.current.hidden = true;
              canvasRef.current.hidden = true;
              btnRef.current.hidden = true;
              setIniciar(false);
             }
          }
        })
      }
    }, 2000)
  }


  return (
    <>
    <div className='d-flex flex-column align-items-center justify-content-between'>
      <div className='d-flex flex-column gap-2 align-items-center justify-content-center overflow-hidden w-100'>
        <video ref={videoRef} className={iniciar ? "" : "d-none"} crossOrigin='anonymus' autoPlay height={300} />
        {iniciar ?
        <div class="spinner-grow text-light position-absolute" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        :""}
      </div>
      {iniciar && !messageInfo ? 
      <>
      <div className='d-flex flex-column'>
        <span>Cargando porfavor espere...</span>
        <span>Esta operacion puede tardar unos minutos...</span>
      </div>
      </> : <span>{messageInfo}</span> }

    {!error ?
      <FormularioAsistencia 
        user={user}
        motivo={motivo} 
        setMotivo={setMotivo} 
        error={inputError} 
        setError={setInputError}
        coordinates={coordinates}
        confirmUser={confirmUser}
        setConfirmUser={setConfirmUser}
      />
      :
      ""
    }
      {inputError?.coordinates ?
      <> 
        <button onClick={() => handleLocation()} className='btn btn-warning mt-4'>Obtener Ubicación</button>
        <span className='text-danger mt-2'>Asegurate de habilitar la geolocalización del navegador</span>
      </>
      :

      ""
      }
    {!error ?
      <button ref={btnRef} className='btn btn-primary w-100 mt-2'
          disabled={inputError?.motivo || inputError?.coordinates ? true : false}
          onClick={() => setIniciar(!iniciar)}
        >{iniciar ? "detener reconocimiento" : "iniciar reconocimiento"}</button>
      
    :""}

    {error ?
    <>
    <p>Ups, {error}</p>

    
    <button className='btn btn-primary w-100 mt-5' data-bs-dismiss="modal" aria-label="Close"
        onClick={() => {
          stopVideo();
          navigate("/dashboard/profile#profile-biometric")
          }
        }
      >
        Configurar Imagen Biometrica
      </button>
    </>
    :
    ""
    }

      <canvas ref={canvasRef} width={520} height={300} className={`position-absolute top-10 ${iniciar ? "" : "d-none"}`}/>
      <img ref={imageRef} width={100} height={200} hidden alt="" />
    </div>

    </>
  )
}