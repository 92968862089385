import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import { Link } from "react-router-dom";
import socket from "../../utils/socketCLient";

export const ChatArea = () => {
  const { user } = useSelector(userSelector);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosAll, setUsuariosAll] = useState([]);
  const [usuarioSelected, setUsuarioSelected] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [mensajes, setMensajes] = useState([]);
  const [errors, setErrors] = useState({});
  const [mensaje, setMensaje] = useState("");
  const [archivo, setArchivo] = useState({});
  const [socketData, setSocketData] = useState({});

  const myRefname = useRef(null)

  const handleSubmit = async(e) => {
    e.preventDefault();

    if(mensaje === ""){
      Swal.fire("ADVERTENCIA!", "Campo mensaje debe estar lleno", "warning");
      return;
    }

    if(!usuarioSelected && !grupo){
      Swal.fire("ADVERTENCIA!", "Debes seleccionar un usuario o grupo para enviar el mensaje", "warning");
      return;
    }

    if(grupo === "general"){
      if(user.role.value !== "admin"){
          Swal.fire("ADVERTENCIA!", "No puedes enviar mensajes generales ya que no eres administrador", "warning");
          return;
      }
    }

    if(user.role.value !== "admin"){
      if(usuarioSelected !== ""){
        let usuario = usuariosAll.find(usuario => usuario.id === usuarioSelected);
        if((user.role.value === "contratista" || user.role.value === "supervisor") && usuario.role.value === "admin"){
          let data = mensajes.find(mensaje => mensaje.para === user.id);
          if(!data){
            Swal.fire("ADVERTENCIA!", "No puedes enviar mensajes al admin ya que no han iniciado una conversación contigo", "warning");
            return;
          }
        }else if(user.role.value === "operario" && (usuario.role.value === "contratista" || usuario.role.value === "supervisor")){
          let data = mensajes.find(mensaje => mensaje.para === user.id);
          if(!data){
            Swal.fire("ADVERTENCIA!", "No puedes enviar mensajes al contratista o supervisor ya que no han iniciado una conversación contigo", "warning");
            return;
          }
        }
      }
    }

    let formData = new FormData();
    formData.append("de", user.id);
    
    formData.append("mensaje", mensaje);

    if(grupo){
      formData.append("grupo", grupo);
    }

    if(usuarioSelected){
      formData.append("para", usuarioSelected);
    }

    if(archivo.name){
      formData.append("files", archivo);
    }

    const URL_API = `${url_api}/api/message/agregar-mensaje` || "http://localhost:3001/api/message/agregar-mensaje";
    try {
      const { data } = await axios.post(URL_API, formData);
      let fecha = new Date();
      socket.emit("mensaje-enviado", { de: user.id, para: usuarioSelected ? usuarioSelected : "", fecha, mensaje: mensaje, archivo: archivo.name ? archivo.name : "", grupo: grupo ? grupo : "" });
      setMensaje("");
      setArchivo({})
      await obtenerMensajesUsuarios();
      await obtenerMensajesGrupo();
    } catch (error) {

    } 
  }

  const obtenerMensajesUsuarios = async() => {
    if(!usuarioSelected){
      return;
    }
    const URL_API = `${url_api}/api/message/obtener-mensajes/${user.id}` || `http://localhost:3001/api/message/obtener-mensajes/${user.id}`;
    try {
      const { data } = await axios.post(URL_API, { para: usuarioSelected });
      setMensajes(data);
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al obtener los mensaje", "warning");
    }
  }


  const obtenerMensajesGrupo = async() => {
    if(!grupo){
      return;
    }
    const URL_API = `${url_api}/api/message/obtener-mensajes-grupo/${grupo}` || `http://localhost:3001/api/message/obtener-mensajes-grupo/${grupo}`;
    try {
      const { data } = await axios.get(URL_API);
      setMensajes(data);
    } catch (error) {
      Swal.fire("ADVERTENCIA!", "Hubo un error al obtener los mensaje", "warning");
    }
  }


  const subirArchivo = (e) => {
    e.preventDefault();
    myRefname.current.click();
  }

  useEffect(() => {
    const obtenerUsuarios = async()=> {
      const URL_API = `${url_api}/api/admin/users` || "http://localhost:3001/api/admin/users";
      try {
        const { data } = await axios.get(URL_API);
        if(user.role.value !== "admin"){
          if(user.role.value === "contratista" || user.role.value === "supervisor"){
            let admin = data.data.filter(usuario => usuario.role.value == "admin")
            setUsuarios(admin);
          }
          const usuariosCoincidentes = data.data.filter(usuario =>
            usuario.projects.some(proyecto =>
              user.projects.some(personaProyecto =>
                personaProyecto.id === proyecto.id
              )
            )
          );
          setUsuarios( (state) => [...state, ...usuariosCoincidentes]);
        }else{
          let users = data.data.filter(usuario => usuario.role.value === "contratista" || usuario.role.value === "supervisor");
          setUsuarios(users);
        }
        setUsuariosAll(data.data);
      } catch (error) {
        setErrors({ error: "hubo un error"});
      }
    }
    obtenerUsuarios();
  }, [])

  useEffect(() => {
    obtenerMensajesUsuarios();
  }, [usuarioSelected])

  useEffect(() => {
    obtenerMensajesGrupo();
  }, [grupo])

  useEffect(() => {
    socket.on("actualizar-mensajes", (data) => {
      setSocketData(data)
    })
  }, [])

  useEffect(() => {
    if(socketData.para === user.id && socketData.de === usuarioSelected){
      setMensajes(state => [...state, socketData]);
    }
  }, [socketData])

  return (
    <>
        <div className='col-12 d-flex container mt-5 gap-2'>
            <div className='col-4 col-md-2 p-2'>
                <p>Contactos</p>
                <div className="bg-primary p-2 rounded d-flex flex-column">
                  <div className='divScroll'>
                  {usuarios.length > 0 ? 
                  <>
                    <a href="#" className={grupo === "general" ? "text-warning" : "text-white"}
                    onClick={() => {
                      setUsuarioSelected(null);
                      setGrupo("general")
                    }}>Mensaje General</a>
                    
                    {usuarios.map((usuario, index) => (
                      
                      <div key={index}>
                      {usuario.id !== user.id ? 
                      
                      <div>
                          <hr/>
                          <a className={usuarioSelected === usuario.id ? "text-warning" : "text-white"} 
                           href="#" onClick={() => {
                            setGrupo(null)
                            setUsuarioSelected(usuario.id)
                           }} >{usuario ? usuario.name : ""} {usuario ? usuario.lastName : ""}</a>
                      </div> 
                      
                      : ""}
                      
                      </div>
                    ))}
                  </>
                  : 
                  
                  <p>no hay usuarios disponibles</p>}
                  </div>
                </div>
            </div>
            <div className='col-8 col-md-10 p-2'>
                <p>Mensajes</p>
                <div className="bg-dark p-2 rounded d-flex flex-column justify-content-center col-12">
                <div className="divScroll">
                  {mensajes.length > 0 ? 
                  
                    mensajes.map((mensaje, index) => (
                      
                        <div key={index} className={`rounded mt-3 mb-3 p-2 text-white col-11 ${mensaje.de === user.id ? "bg-primary" : "bg-secondary"}`}>
                          <div className='d-flex justify-content-between align-items-center'>
                            <p>Fecha {mensaje.fecha}</p>
                            <p>De {mensaje.de === user.id ? "mí" : usuariosAll.find(user => user.id === mensaje.de).name}</p>
                          </div>
                          <div>
                            <p className='fw-bold'>{mensaje.mensaje}</p>
                            
                            {mensaje.archivo ? 
                            <p className='text-warning'>Archivo Recibido 
                              <Link
                                    to={url_api+"/" + mensaje.archivo}
                                    target="_blank"
                                  > {mensaje.archivo}
                              </Link>
                            </p>
                            
                            : ""
                            
                            }
                            
                          </div>
                        </div>
                      
                    ))
                  
                  : 
                  
                  <p className='text-white'>No hay mensajes...</p>
                  }
                </div>
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <textarea className='form-control w-100' value={mensaje}
                      onChange={(e) => setMensaje(e.target.value)}
                    ></textarea>
                    <div>
                      <button type='submit' className='btn btn-primary mt-2 me-3'><i className="bi bi-send-fill"
                      ></i></button>
                      <input ref={myRefname} onChange={(e) => setArchivo(e.target.files[0])} type="file" className='form-control d-none'/>
                      <button onClick={(e) => subirArchivo(e)} className='btn btn-secondary mt-2 me-2'><i className="bi bi-paperclip"></i></button>
                      {archivo?.name ? archivo.name : ""}
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </>
  )
}
