import { useEffect, useState } from "react"

export const PreguntaMultiple = ({ content, setDataForm, reset, setReset }) => {

  const [respuesta, setRespuesta] = useState({ respuestas: [] });

  useEffect(() => {
    setDataForm(respuesta);
  }, [respuesta])

  useEffect(() => {
    if(reset){
      setRespuesta({ respuestas: [] })
      setReset(false);
    }
  }, [reset])

  return (
    <div className='card p-2 bg-secondary col-12 col-md-6 mt-2'>
      <p className='text-center text-white'>Pregunta Multiple</p>
      <h3 className='text-center text-white fw-bold'>{content.pregunta}</h3>
      <div className='row justify-content-center align-items-center p-2'>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(content?.respuesta1)){
              let respuestas = respuesta.respuestas.filter(item => item !== content?.respuesta1);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: respuestas  })
            }else{
              if(respuesta.respuestas.length >= 2) return;
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, content.respuesta1]  })
            }
          } }
        >
          {content.respuesta1} {respuesta?.respuestas?.includes(content?.respuesta1) ? <i class='bx bx-check-double'></i> : ""}
        </button>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
         onClick={() => {
          if(respuesta?.respuestas?.includes(content?.respuesta2)){
            let respuestas = respuesta.respuestas.filter(item => item !== content?.respuesta2);
            setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: respuestas  })
          }else{
            if(respuesta.respuestas.length >= 2) return;
            setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, content.respuesta2]  })
          }
        } }
        >
          {content.respuesta2} {respuesta?.respuestas?.includes(content?.respuesta2) ? <i class='bx bx-check-double'></i> : ""}
        </button>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(content?.respuesta3)){
              let respuestas = respuesta.respuestas.filter(item => item !== content?.respuesta3);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: respuestas })
            }else{
              if(respuesta.respuestas.length >= 2) return;
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, content.respuesta3]  })
            }
          } }
        >
          {content.respuesta3} {respuesta?.respuestas?.includes(content?.respuesta3) ? <i class='bx bx-check-double'></i> : ""}
        </button>
        <button className='btn btn-dark text-white fw-bold col-12 col-md-6 text-center mt-2'
          onClick={() => {
            if(respuesta?.respuestas?.includes(content?.respuesta4)){
              let respuestas = respuesta.respuestas.filter(item => item !== content?.respuesta4);
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: respuestas })
            }else{
              if(respuesta.respuestas.length >= 2) return;
              setRespuesta({ ...respuesta, tipo: content.tipo, id: content.id, respuestas: [...respuesta.respuestas, content.respuesta4]  })
            }
          } }
        >
          {content.respuesta4} {respuesta?.respuestas?.includes(content?.respuesta4) ? <i class='bx bx-check-double'></i> : ""}
        </button>
      </div>
    </div>
  )
}
