import React, { useEffect, useState } from 'react';
import { addAsistenciaUser, limpiarInfo } from '../../slices/asistencia/asistenciaSlice';
import { useDispatch, useSelector } from 'react-redux';
import { asistenciaSelector, getAsistenciasUser } from '../../slices/asistencia/asistenciaSlice';
import Swal from "sweetalert2";

export const FormularioAsistencia = ({ user, motivo, setMotivo, error, setError, coordinates, confirmUser }) => {

  const { errores, success } = useSelector(asistenciaSelector);

  const [infoEnviada, setInfoEnviada] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async() => {
    let data = {
      motivo,
      coordinates
    }
    
    let errorData = validator(data);

    if(errorData?.motivo || errorData?.coordinates){
      setError({...error, motivo: errorData.motivo});
      return;
    }

    dispatch(addAsistenciaUser(user.id, data));
    setTimeout(() => {
      dispatch(getAsistenciasUser(user.id));
    }, 1000)
    
  }

  useEffect(() => {
    let errorData = validator({ motivo: motivo });
    if(errorData?.motivo){
      setError({...error, motivo: errorData.motivo});
      return;
    }else{
      setError({...error, motivo: ""})
    }
  }, [motivo])

  useEffect(() => {
    setMotivo("");
  }, [infoEnviada])

  useEffect(() => {
    if(success){
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: success,
        showConfirmButton: false,
        timer: 4000,
      });
      dispatch(limpiarInfo());
      setInfoEnviada(true);
    }
  }, [success])

  useEffect(() => {
    if(errores){
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: errores,
        showConfirmButton: false,
        timer: 4000,
      });
      dispatch(limpiarInfo());
    }
  }, [errores])

  useEffect(() => {
    
    if(confirmUser){
      handleSubmit();
    }
  }, [confirmUser])


  const validator = (data) => {
    let error = {};
    if(data?.coordinates?.length === 0){
      error = {...error, coordinates: "Debes activar la geolocalización del dispositivo"};
    }
    if(data.motivo == ""){
      error = {...error, motivo: "Debes agregar el motivo"};
    }
    return error;
  }
  
  return (
    <>
    {!infoEnviada ?
      <div className={`mt-3`}>
        <label>Motivo del Registro</label>
        <select className='form-control' onChange={(e) => setMotivo(e.target.value)}>
          <option value="">--seleccionar--</option>
          <option value="entrada a la jornada">entrada a la jornada</option>
          <option value="salida de la jornada">salida de la jornada</option>
          <option value="salida de almuerzo">salida de almuerzo</option>
          <option value="ingreso de almuerzo">ingreso de almuerzo</option>
        </select>
        {error?.motivo ? <span className='text-danger'>{error?.motivo}</span> : "" }
      </div>
    :
    ""  
    }
    </>
  )
}
