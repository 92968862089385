import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadBiometricImage, userSelector, clearInfo } from "../../slices/user/userSlice";
import { showAlertNormal, showAlertWithTimer } from "../../utils/showAlert";
import { validateProfileImage } from "../../utils/validation";
import { getBiometricImage, reconocimientoSelector } from "../../slices/reconocimiento/reconocimiento";
import { url_api } from "../../utils/config";
import Swal from "sweetalert2";

const newData = {
  image: "",
  url: "",
};

export const FormChangeBiometric = ({ locationBiometric }) => {
  const [file, setFile] = useState(newData);
  const [error, setError] = useState(newData);
  const [loading, setLoading] = useState(false);
  const [previewImage, setUrlPreviewImage] = useState("");
  const { status, message, user } = useSelector(userSelector);
  const { imagenBiometrica } = useSelector(reconocimientoSelector);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const validateAccess = useCallback(async () => {
    if (status === 1) {
      showAlertWithTimer(message, "", "success");
    } else if (status === 0) {
      showAlertNormal(message, "", "error");
    }
    dispatch(clearInfo());
    setLoading(false)
    dispatch(getBiometricImage(user.id));
  }, [status, message]);

  useEffect(() => {
    validateAccess();
    return () => {
      clearInputs();
    };
  }, [validateAccess]);

  useEffect(() => {
    setError(validateProfileImage({ ...file }));
  }, [file]);

  useEffect(() => {
    dispatch(getBiometricImage(user.id));
  }, [])

  useEffect(() => {
    if(imagenBiometrica){
      let urlImage = url_api + "/biometric/" + user.dni + "/" + imagenBiometrica;
      setUrlPreviewImage(urlImage);
    }
  }, [imagenBiometrica])

  const handleChange = (e) => {
    const image = e.target.files[0];
    if(!image) return;
    const url = URL.createObjectURL(image);
    setFile({...file, image, url});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(imagenBiometrica){
      Swal.fire({
        title: 'Estas seguro que quieres cambiar tu imagen, esta acción puede tardar varios minutos?',
        showDenyButton: true,
        confirmButtonText: 'Iniciar',
        denyButtonText: `No Iniciar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          guardarImagen();
        } else if (result.isDenied) {
          Swal.fire('Has decidido no cambiar tu imagen biometrica', '', 'info');
        }
      })
    }else{
      guardarImagen();
    }
  };

  const guardarImagen = async() => {
    if(!file?.image){
      showAlertNormal("Debes seleccionar una imagen", "", "error");
      return;
    }
    const formData = new FormData();
    formData.append("image", file.image);
    setLoading(true);
    dispatch(
        uploadBiometricImage(user?.id, formData)
    );
  }

  const clearInputs = () => {
    setFile(newData);
    setError(newData);
    inputRef.current?.focus();
  };

  const errorMessage = (input) => (
    <span className={`${input ? "d-inline-flex" : "d-none"} invalid-feedback`}>
      {input}
    </span>
  );

  return (
    <div
      className={`tab-pane fade show  profile-edit pt-3 ${locationBiometric ? "active" : ""}`}
      id="profile-biometric"
    >
      {!loading ?
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="d-flex flex-column gap-2">
            <label
              htmlFor="profileImage"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Imágen Biometrica
            </label>
            {imagenBiometrica ? <span className="text-success">Ya tienes una Imagen en el sistema</span> : <span className="text-danger">Aún no tienes una Imagen en el sistema</span>}
            <p>Guarda tu imagen biometrica para que puedas guardar tus asistencias</p>
          </div>
          <div className="col-md-8 col-lg-9">
            <input
              type="file"
              className="form-control"
              onChange={handleChange}
            />
            <div className="pt-2">
              <img src={file.url ? file.url : previewImage} alt="Profile" className="mt-2" />
              {errorMessage(error.image)}
            </div>
          </div>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary">
            Guardar Cambios
          </button>
        </div>
      </form>
      :
      <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-grow text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span>Subiendo espere un momento...</span>
        <span>Esta tarea puede tardar un par de minutos...</span>
      </div>
      </>
      }
    </div>
  );
};
