import React, { useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import useMenuToggle from "../hooks/useMenuToggle";
import { userSelector } from "../slices/user/userSlice";
import { useSelector } from "react-redux";

export const Sidebar = () => {
  const { menu, setMenu } = useMenuToggle();
  const { user } = useSelector(userSelector);
  /*const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );*/
  //setMatches( e.matches )
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMenu(false));
  }, []);


  return (
    <aside
      id="sidebar"
      className="sidebar"
      style={{ left: menu ? "0px" : "-300px", width: "15rem" }}
    >
      <ul className="sidebar-nav" id="sidebar-nav">
      {user.role.value && user.role.value==="admin" ?
      <>
        
        <li className="nav-item">
          <NavLink to={"/dashboard/projects"} className="nav-link collapsed">
            <i className="bi bi-card-list"></i>
            <span>Empresas</span>
          </NavLink>
        </li>
         
        <li className="nav-item">
          <NavLink to={"/dashboard/cargos"} className="nav-link collapsed">
            <i className="bi bi-diagram-2-fill"></i>
            <span>Cargos</span>
          </NavLink>
        </li>
        
        <li className="nav-item">
          <NavLink to={"/dashboard/usuarios"} className="nav-link collapsed">
            <i className="bi bi-person-rolodex"></i>
            <span>Usuarios</span>
          </NavLink>
        </li>
       
        <li className="nav-item">
          <Link to={"/dashboard/contratistas"} className="nav-link collapsed">
            <i className="bi bi-person-fill"></i>
            <span>Subcontratistas</span>
          </Link>
        </li>
       
        <li className="nav-item">
          <Link to={"/dashboard/supervisores"} className="nav-link collapsed">
            <i className="bi bi-person-lines-fill"></i>
            <span>Supervisores</span>
          </Link>
        </li>


        </>
        :''}
       
        <li className="nav-item">
          <Link to={"/dashboard/operarios"} className="nav-link collapsed">
            <i className="bi bi-people-fill"></i>
            <span>Operarios</span>
          </Link>
        </li>
        
        {user.role.value && user.role.value !=="admin" ?
        <>
        <li className="nav-item">
          <Link to={"/dashboard/evaluaciones-user"} className="nav-link collapsed">
            <i className="bi bi-people-fill"></i>
            <span>Evaluaciones</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/dashboard/asistencias-usuario"} className="nav-link collapsed">
            <i class='bx bxs-hard-hat' ></i>
            <span>Asistencias</span>
          </Link>
        </li>
        </>
        :
        ""
        }


        {user.role.value && user.role.value==="admin" ? 
        
        <>
        <li className="nav-item">
          <Link to={"/dashboard/certificados-fijos"} className="nav-link collapsed">
            <i className="bi bi-input-cursor-text"></i>
            <span>Certificados Fijos</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link to={"/dashboard/evaluaciones"} className="nav-link collapsed">
            <i class="bi bi-file-earmark-richtext"></i>
            <span>Evaluaciones</span>
          </Link>
        </li> 

        <li className="nav-item">
          <Link to={"/dashboard/asistencias"} className="nav-link collapsed">
            <i class='bx bxs-hard-hat' ></i>
            <span>Asistencias</span>
          </Link>
        </li> 

        </>
        
        : ""}

        <li className="nav-item">
          <Link to={"/dashboard/send-messages"} className="nav-link collapsed">
            <i className="bi bi-telegram"></i>
            <span>Área de mensajeria</span>
          </Link>
        </li>

        {user.role.value && user.role.value==="admin" ? 
        
        <>

        <li className="nav-item">
          <Link to={"/dashboard/ajustes"} className="nav-link collapsed">
            <i className="bi bi-nut"></i>
            <span>Ajustes</span>
          </Link>
        </li>

        </>
        
        : ""}
       
      </ul>
    </aside>
  );
};
