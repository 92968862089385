import React from 'react'

const Enumeracion = ({ setPreguntas, preguntas, index, typeQuestions }) => {
  return (
    <>
     <div className='card bg-dark p-3'>
      <label className='text-white'>Pregunta {index + 1} tipo {typeQuestions[index]}</label>
        <input type='text' placeholder='Escribe la Pregunta' className='form-control'
        value={preguntas[index]?.pregunta}
          onChange={(e) => {
            let previewElement = preguntas.find(el => el.indice === index);
            if (previewElement) {
              const indexElement = preguntas.findIndex(el => el.indice === index);
              preguntas[indexElement].pregunta = e.target.value;
              setPreguntas([...preguntas]);
            }else{
              setPreguntas((preguntas) => [...preguntas, { pregunta: e.target.value, indice: index, tipo: typeQuestions[index] }])
            }
          }}
        />
        <div className='d-flex flex-column gap-3 mt-2'>
          <label className='text-white'>Escribe las respuestas en orden descendente</label>
          <div className='d-flex col-8 gap-2'>
            <input type='text' placeholder='Respuesta 1' className='form-control'
            value={preguntas[index]?.respuesta1}
              onChange={(e) => {
                let previewElement = preguntas.find(el => el.indice === index);
                if (previewElement) {
                  const indexElement = preguntas.findIndex(el => el.indice === index);
                  preguntas[indexElement].respuesta1 = e.target.value;
                  setPreguntas([...preguntas]);
                }else{
                  setPreguntas((preguntas) => [...preguntas, { respuesta1: e.target.value, indice: index, tipo: typeQuestions[index] }])
                }
              }}
            />
          </div>
          <div className='d-flex col-8 gap-2'>
            <input type='text' placeholder='Respuesta 2' className='form-control'
            value={preguntas[index]?.respuesta2}
              onChange={(e) => {
                let previewElement = preguntas.find(el => el.indice === index);
                if (previewElement) {
                  const indexElement = preguntas.findIndex(el => el.indice === index);
                  preguntas[indexElement].respuesta2 = e.target.value;
                  setPreguntas([...preguntas]);
                }else{
                  setPreguntas((preguntas) => [...preguntas, { respuesta2: e.target.value, indice: index, tipo: typeQuestions[index] }])
                }
              }}
            />
          </div>
          <div className='d-flex col-8 gap-2'>
            <input type='text' placeholder='Respuesta 3' className='form-control'
            value={preguntas[index]?.respuesta3}
              onChange={(e) => {
                let previewElement = preguntas.find(el => el.indice === index);
                if (previewElement) {
                  const indexElement = preguntas.findIndex(el => el.indice === index);
                  preguntas[indexElement].respuesta3 = e.target.value;
                  setPreguntas([...preguntas]);
                }else{
                  setPreguntas((preguntas) => [...preguntas, { respuesta3: e.target.value, indice: index, tipo: typeQuestions[index] }])
                }
              }}
            />
          </div>
          <div className='d-flex col-8 gap-2'>
            <input type='text' placeholder='Respuesta 4' className='form-control'
            value={preguntas[index]?.respuesta4}
              onChange={(e) => {
                let previewElement = preguntas.find(el => el.indice === index);
                if (previewElement) {
                  const indexElement = preguntas.findIndex(el => el.indice === index);
                  preguntas[indexElement].respuesta4 = e.target.value;
                  setPreguntas([...preguntas]);
                }else{
                  setPreguntas((preguntas) => [...preguntas, { respuesta4: e.target.value, indice: index, tipo: typeQuestions[index] }])
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Enumeracion;